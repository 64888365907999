<div class="flex justify-content-center users-content flex-1">
  <div class="center-panel large form-panel">
    <div><span class="block-title">Liste des utilisateurs</span></div>
    <div class="separtor-line"></div>
    <div class="user-list mt-2">
      <p-table [value]="users">
        <ng-template pTemplate="header">
          <tr>
            <th>Name</th>
            <th>Date création</th>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Téléphone</th>
            <th>Validé</th>
            <th>Actif</th>
            <th class="icon-action"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
          <tr>
            <td>{{ user.name }}</td>
            <td>{{ user.creationDate | date: 'dd/MM/yyyy' }}</td>
            <td>{{ user.prenom }}</td>
            <td>{{ user.nom }}</td>
            <td>{{ user.telephone }}</td>
            <td [ngClass]="user.validate ? 'valid' : 'unvalid'">{{ user.validate ? 'Oui' : 'Non' }}</td>
            <td [ngClass]="user.enabled ? 'valid' : 'unvalid'">{{ user.enabled ? 'Oui' : 'Non' }}</td>
            <td class="icon-action"><em [ngClass]="user.enabled ? 'pi pi-ban' : 'pi pi-check-circle'" (click)="disableEnableUser(user)"></em></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr><td colspan="8">Aucun utilisateurs</td></tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
