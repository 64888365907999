<div class="event-main flex flex-column">
  <app-header [(sidebarMenu)]="sidebarMenu"></app-header>

  <div class="event-content flex flex-column">
    <!-- <p-messages class="global-messages" [(value)]="messages"></p-messages> -->
    <router-outlet></router-outlet>
    <p-toast position="bottom-left"></p-toast>
  </div>

  <app-side-menu [(sidebarMenu)]="sidebarMenu"></app-side-menu>
</div>
