import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Tarif } from "../models/commande.model";

@Injectable({providedIn: 'root'})
export class TarifService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<Tarif[]> {
    return this.http.get<Tarif[]>('/tarif/findAll');
  }

  save(tarif: Tarif): Observable<Tarif> {
    return this.http.post<Tarif>('/tarif/save', tarif);
  }

  findById(id: number): Observable<Tarif> {
    return this.http.get<Tarif>('/tarif/findById/' + id);
  }

  getListNbPersonne(): Observable<number[]> {
    return this.http.get<number[]>('/tarif/getListNbPersonne');
  }

  findByNbParticipant(nbParticipant: number): Observable<Tarif> {
    return this.http.get<Tarif>('/tarif/findByNbParticipant/' + nbParticipant);
  }
}
