<div class="flex justify-content-center tarif-content flex-1">
  <div class="center-panel large form-panel ">
    <div><span class="block-title">Liste des tarifs</span></div>
    <div class="separtor-line"></div>
    <div class="flex justify-content-end gap-3">
      <button class="p-button-success" pButton type="button" (click)="addTarif()">Ajouter</button>
    </div>
    <div class="tarif-list mt-2">
      <p-table [value]="tarifs">
        <ng-template pTemplate="header">
          <tr>
            <th>Description</th>
            <th class="w-7rem col-num">Nb pers.</th>
            <th class="w-6rem">Récurcif</th>
            <th class="w-7rem col-num">Prix</th>
            <th class="w-3rem icon-action"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tarif>
          <tr>
            <td>{{ tarif.description }}</td>
            <td class="col-num">{{ tarif.nombre === 0 ? 'Illimité' : tarif.nombre }}</td>
            <td class="col-bool">{{ tarif.recursif ? 'Oui' : 'Non' }}</td>
            <td class="col-num">${{ tarif.prix }}</td>
            <td class="icon-action"><em class="pi pi-pencil"(click)="modify(tarif)"></em></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr><td colspan="5">Aucun tarifs</td></tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
