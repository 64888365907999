import { SafeHtmlPipe } from './shared/pipe/safe-html.pipe';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { JwtModule } from '@auth0/angular-jwt';

import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { DataViewModule } from 'primeng/dataview';
import { TagModule } from 'primeng/tag';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ImageModule } from 'primeng/image';
import { CheckboxModule } from 'primeng/checkbox';
import { CarouselModule } from 'primeng/carousel';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TimelineModule } from 'primeng/timeline';
import { EditorModule } from 'primeng/editor';

import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';

import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { HttpErrorFilter } from './shared/http/httpErrorFilter';
import { HttpFilter } from './shared/http/httpFilter';
import { HomeComponent } from './component/home/home.component';
import { ProfileComponent } from './component/profile/profile.component';
import { AppRouting } from './app.routing';
import { LoginComponent } from './component/login/login.component';
import { SideMenuComponent } from './component/side-menu/side-menu.component';
import { EventComponent } from './component/event/event.component';
import { CreateEventComponent } from './component/event/create-event/create-event.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { JeuPisteEventComponent } from './component/event/jeu-piste-event/jeu-piste-event.component';
import { UserListComponent } from './component/shared/user-list/user-list.component';
import { EventActifComponent } from './component/event/event-actif/event-actif.component';
import { JeuPisteComponent } from './component/play/jeu-piste/jeu-piste.component';
import { PhotoCardComponent } from './component/play/jeu-piste/photo-card/photo-card.component';
import { CompteComponent } from './component/compte/compte.component';
import { CreateCompteComponent } from './component/compte/create-compte/create-compte.component';
import { CommandeComponent } from './component/commande/commande.component';
import { TarifComponent } from './component/tarif/tarif.component';
import { CreateTarifComponent } from './component/tarif/create-tarif/create-tarif.component';
import { CreateCommandeComponent } from './component/commande/create-commande/create-commande.component';
import { TarifDialogComponent } from './component/tarif/tarif-dialog/tarif-dialog.component';
import { EditJeuPisteComponent } from './component/event/jeu-piste-event/edit-jeu-piste/edit-jeu-piste.component';
import { PresetJeuPisteComponent } from './component/event/jeu-piste-event/preset-jeu-piste/preset-jeu-piste.component';
import { CguDetailComponent } from './component/shared/cgu-detail/cgu-detail.component';
import { FactureComponent } from './component/commande/facture/facture.component';
import { UsersComponent } from './component/users/user-list/users.component';
import { HomeLeftComponent } from './component/home/home-left/home-left.component';
import { HomeRightComponent } from './component/home/home-right/home-right.component';
import { CopyPresetComponent } from './component/event/copy-preset/copy-preset.component';
import { PresetListComponent } from './component/event/preset-list/preset-list.component';
import { EventConfigDetailComponent } from './component/event/event-config-detail/event-config-detail.component';
import { EventUserListComponent } from './component/event/event-user-list/event-user-list.component';
import { CreateEventDialogComponent } from './component/event/create-event-dialog/create-event-dialog.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LadderStepComponent } from './component/event/ladder/ladder-step/ladder-step.component';
import { JeuPisteScoreComponent } from './component/play/jeu-piste/jeu-piste-score/jeu-piste-score.component';
import { EditLadderStepComponent } from './component/event/ladder/edit-ladder-step/edit-ladder-step.component';
import { LadderComponent } from './component/play/ladder/ladder.component';
import { TeamComponent } from './component/play/team/team.component';
import { EditTeamComponent } from './component/play/team/edit-team/edit-team.component';
import { LadderCheckpointComponent } from './component/play/ladder/ladder-checkpoint/ladder-checkpoint.component';
import { InscriptionPisteComponent } from './component/play/jeu-piste/inscription-piste/inscription-piste.component';
import { InscriptionPisteTeamComponent } from './component/play/jeu-piste/inscription-piste/inscription-piste-team/inscription-piste-team.component';
import { ValidLadderCkptComponent } from './component/play/ladder/ladder-checkpoint/valid-ladder-ckpt/valid-ladder-ckpt.component';
import { LadderSuiviComponent } from './component/play/ladder/ladder-suivi/ladder-suivi.component';
import { UserNamePipe } from './shared/pipe/user-name.pipe';
import { TextEditorComponent } from './component/shared/text-editor/text-editor.component';
import { NewsViewerComponent } from './component/shared/news-viewer/news-viewer.component';
import { TextEditorViewerComponent } from './component/shared/text-editor-viewer/text-editor-viewer.component';
import { ScoreBoardComponent } from './component/play/score-board/score-board.component';
import { AddSuiviRightComponent } from './component/event/add-suivi-right/add-suivi-right.component';

export function getToken(): string {
  const token = localStorage.getItem('access.token');
  return token ? token : '';
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    ProfileComponent,
    LoginComponent,
    SideMenuComponent,
    EventComponent,
    CreateEventComponent,
    JeuPisteEventComponent,
    UserListComponent,
    EventActifComponent,
    JeuPisteComponent,
    PhotoCardComponent,
    CompteComponent,
    CreateCompteComponent,
    CommandeComponent,
    TarifComponent,
    CreateTarifComponent,
    CreateCommandeComponent,
    TarifDialogComponent,
    EditJeuPisteComponent,
    PresetJeuPisteComponent,
    CguDetailComponent,
    FactureComponent,
    UsersComponent,
    HomeLeftComponent,
    HomeRightComponent,
    CopyPresetComponent,
    PresetListComponent,
    EventConfigDetailComponent,
    EventUserListComponent,
    CreateEventDialogComponent,
    LadderStepComponent,
    JeuPisteScoreComponent,
    EditLadderStepComponent,
    LadderComponent,
    TeamComponent,
    EditTeamComponent,
    LadderCheckpointComponent,
    InscriptionPisteComponent,
    InscriptionPisteTeamComponent,
    ValidLadderCkptComponent,
    LadderSuiviComponent,
    UserNamePipe,
    TextEditorComponent,
    NewsViewerComponent,
    TextEditorViewerComponent,
    SafeHtmlPipe,
    ScoreBoardComponent,
    AddSuiviRightComponent,
  ],
  imports: [
    AppRouting,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
      }
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient]
        }
    }),
    AutoCompleteModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    CheckboxModule,
    ConfirmDialogModule,
    DataViewModule,
    DropdownModule,
    DynamicDialogModule,
    EditorModule,
    FileUploadModule,
    ImageModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextareaModule,
    InputTextModule,
    MenuModule,
    MessagesModule,
    OverlayPanelModule,
    RippleModule,
    SidebarModule,
    TableModule,
    TagModule,
    TimelineModule,
    ToastModule,
    TooltipModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorFilter, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpFilter, multi: true },
    MessageService,
    DialogService,
    ConfirmationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
