import { Pipe, PipeTransform } from "@angular/core";
import { User } from "src/app/models/user.model";

@Pipe({
  name: 'username'
})
export class UserNamePipe implements PipeTransform {
  transform(user: User | undefined): string {
    if (user) {
      return user.prenom + ' ' + user.nom;
    }
    return '';
  }

}
