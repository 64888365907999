import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, map, Observable, of, switchMap, zip } from "rxjs";
import { User, UserRights } from "../models/user.model";

@Injectable({providedIn: 'root'})
export class UserService {

  private userConnected = new BehaviorSubject<User | undefined>(undefined);
  private userRights = new BehaviorSubject<UserRights>({guest: true, user: false, admin: false});

  constructor(private http: HttpClient) {}

  getUserConnected(): Observable<User | undefined> {
    return this.userConnected.asObservable();
  }

  updateUserConnected(user: User) {
    this.userConnected.next(user);
  }

  clearUserConnected() {
    this.userConnected.next(undefined);
  }

  getUserRights(): Observable<UserRights> {
    return this.userRights.asObservable();
  }

  updateUserRights(userRights: UserRights) {
    this.userRights.next(userRights);
  }

  clearUserRights() {
    this.userRights.next({guest: true, user: false, admin: false});
  }

  getUserInfos(login: string): Observable<User> {
    return this.http.get<User>('/user/infos/' + login).pipe(
      map(user => user as User)
    );
  }

  setUserConnected(login: string): Observable<User | undefined> {
    return this.getUserInfos(login).pipe(
      switchMap(user => {
        if (user) {
          this.userConnected.next(user);
          return combineLatest([of(user), this.getRights(user.id!)]);
        }
        return zip(of(undefined), of(undefined));
      }), switchMap(([user, userRights]) => {
        if (userRights) {
          this.updateUserRights(userRights);
        }
        return of(user);
      }),
    )
  }

  getAuthenticatedUser(): Observable<User> {
    return this.http.get<User>('/auth/authenticatedUser').pipe(
      switchMap(user => {
        this.userConnected.next(user);
        return of(user);
      })
    );
  }

  getUserDetails(): Observable<User> {
    return this.http.get<User>('/user/details');
  }

  save(user: User): Observable<User> {
    return this.http.post<User>('/user/save', user);
  }

  getHomeUser(): Observable<User> {
    return this.http.get<User>('/home/user');
  }

  findByNomPrenom(query: string): Observable<User[]> {
    return this.http.get<User[]>('/user/findByNomPrenom/' + query);
  }

  getRights(id: number): Observable<UserRights> {
    return this.http.get<UserRights>('/user/getUserRights/' + id);
  }

  findAll(): Observable<User[]> {
    return this.http.get<User[]>('/user/findAll');
  }

  disableEnableUser(id: number): Observable<User> {
    return this.http.get<User>('/user/disableEnableUser/' + id);
  }

  getConnectedUser(login: string | undefined) {
    if (this.userConnected.getValue() === undefined && login) {
      return this.setUserConnected(login);
    }
    return this.userConnected.asObservable();
  }

}
