import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Event, EventConfigDetail, EventLadder, EventLadderScore, EventLadderStep, EventPiste, EventPisteScore, EventPisteSteps, StatusEvent } from "../models/event.model";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class EventService {
  constructor(private http: HttpClient) {}

  findByIdAndOwnerId(id: number, ownerId: number): Observable<Event> {
    return this.http.get<Event>('/event/find/' + id + '/' + ownerId);
  }

  findAllByUserId(id: number): Observable<Event[]> {
    return this.http.get<Event[]>('/event/findAll/' + id);
  }

  findPisteByEventIdUserId(eventId: number, userId: number): Observable<EventPiste> {
    return this.http.get<EventPiste>('/event/findPiste/' + eventId + '/' + userId);
  }

  save(event: Event): Observable<Event> {
    return this.http.post<Event>('/event/save', event);
  }

  savePisteStep(step: EventPisteSteps): Observable<EventPisteSteps> {
    return this.http.post<EventPisteSteps>('/event/savePisteStep', step);
  }

  updateStatusReady(id: number, ready: boolean): Observable<Event> {
    return this.http.get<Event>('/event/updateStatusReady/' + id + '/' + ready);
  }

  findAllReady(userId: number): Observable<Event[]> {
    return this.http.get<Event[]>('/event/findAllReady/' + userId);
  }

  findJeuPisteToPlay(eventId: number, userId: number): Observable<EventPiste> {
    return this.http.get<EventPiste>('/event/jeuPisteToPlay/' + eventId + '/' + userId);
  }

  findJeuPisteToPlayByCode(code: string): Observable<EventPiste> {
    return this.http.get<EventPiste>('/event/jeuPisteToPlayByCode/' + code);
  }

  getEventStatus(id: number): Observable<StatusEvent> {
    return this.http.get<StatusEvent>('/event/getStatus/' + id);
  }

  updateStatus(id: number, status: string): Observable<StatusEvent> {
    return this.http.get<StatusEvent>('/event/updateStatus/' + id + '/' + status);
  }

  validedCode(stepId: number, teamId: number, code: string): Observable<EventPisteSteps> {
    return this.http.get<EventPisteSteps>('/event/validedCode/' + stepId + '/' + teamId + '/' + code)
  }

  existsByCode(code: string): Observable<boolean> {
    return this.http.get<boolean>('/event/existsByCode/' + code);
  }

  findByCode(code: string): Observable<Event> {
    return this.http.get<Event>('/event/findByCode/' + code);
  }

  canJoinEvent(eventId: number, userId: number): Observable<boolean> {
    return this.http.get<boolean>('/event/canJoinEvent/' + eventId + '/' + userId);
  }

  canJoinEventCode(code: string, userId: number): Observable<boolean> {
    return this.http.get<boolean>('/event/canJoinEventCode/' + code + '/' + userId);
  }

  findPisteStep(id: number): Observable<EventPisteSteps> {
    return this.http.get<EventPisteSteps>('/event/findPisteStep/' + id);
  }

  findByPresetAndType(type: string): Observable<Event[]> {
    return this.http.get<Event[]>('/event/findByPresetAndType/' + type);
  }

  findStepByEventId(eventId: number): Observable<EventPisteSteps[]> {
    return this.http.get<EventPisteSteps[]>('/event/findStepByEventId/' + eventId);
  }

  copyStepsFromEvent(eventPisteId: number, eventId: number): Observable<EventPisteSteps[]> {
    return this.http.get<EventPisteSteps[]>('/event/copyStepsFromEvent/' + eventPisteId + '/' + eventId);
  }

  findLastPreset(): Observable<Event[]> {
    return this.http.get<Event[]>('/event/findLastPreset');
  }

  findAllPreset(): Observable<Event[]> {
    return this.http.get<Event[]>('/event/findAllPreset');
  }

  copyFromPreset(presetId: number, compteId: number, participants: number): Observable<Event> {
    return this.http.get<Event>('/event/copyFromPreset/' + presetId + '/' + compteId + '/' + participants);
  }

  getConfigDetail(id: number): Observable<EventConfigDetail> {
    return this.http.get<EventConfigDetail>('/event/getConfigDetail/' + id);
  }

  findUserEvents(): Observable<Event[]> {
    return this.http.get<Event[]>('/event/findUserEvents');
  }

  getEventPisteScore(id: number): Observable<EventPisteScore[]> {
    return this.http.get<EventPisteScore[]>('/event/getEventPisteScore/' + id);
  }

  validateEventPisteScore(id: number, teamId: number): Observable<EventPisteScore[]> {
    return this.http.get<EventPisteScore[]>('/event/validateEventPisteScore/' + id + '/' + teamId);
  }

  findLadderByEventIdUserId(eventId: number, userId: number): Observable<EventPiste> {
    return this.http.get<EventPiste>('/event/findLadder/' + eventId + '/' + userId);
  }

  saveLadderStep(step: EventLadderStep): Observable<EventLadderStep> {
    return this.http.post<EventLadderStep>('/event/saveLadderStep', step);
  }

  findLadderStep(id: number): Observable<EventLadderStep> {
    return this.http.get<EventLadderStep>('/event/findLadderStep/' + id);
  }

  findLadderToPlay(eventId: number, userId: number): Observable<EventLadder> {
    return this.http.get<EventLadder>('/event/findLadderToPlay/' + eventId + '/' + userId);
  }

  findLadderToPlayByCode(code: string): Observable<EventLadder> {
    return this.http.get<EventLadder>('/event/findLadderToPlayByCode/' + code);
  }

  addTeamToEventLadder(teamId: number, eventLadderId: number): Observable<boolean> {
    return this.http.get<boolean>('/event/addTeamToEventLadder/' + teamId + '/' + eventLadderId);
  }

  findLadderScoreByEventLadderId(eventLadderId: number): Observable<EventLadderScore[]> {
    return this.http.get<EventLadderScore[]>('/event/findLadderScoreByEventLadderId/' + eventLadderId);
  }

  validateLadderScoreTime(teamId: number): Observable<EventLadderScore> {
    return this.http.get<EventLadderScore>('/event/validateLadderScoreTime/' + teamId);
  }

  validateLadderScorePoint(teamId: number, point: number): Observable<EventLadderScore> {
    return this.http.get<EventLadderScore>('/event/validateLadderScorePoint/' + teamId + '/' + point);
  }

  findLadderScoreByEventLadderIdTeamId(eventLadderId: number, teamId: number): Observable<EventLadderScore> {
    return this.http.get<EventLadderScore>('/event/findLadderScoreByEventLadderIdTeamId/' + eventLadderId + '/' + teamId);
  }

}
