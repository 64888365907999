import { Injectable } from "@angular/core";
import { Message } from "primeng/api";

@Injectable({providedIn: 'root'})
export class GlobalMessageService {
  messages: Message[] = [];

  public addMessage(message: Message) {
    this.messages.push(message);
  }

  public addMessages(messages: Message[]) {
    this.messages.push(...messages);
  }

  public addErrorMessage(message: string) {
    this.messages.push({severity:'error', summary:'Erreur', detail:message});
  }

  public clearMessages() {
    this.messages = [];
  }
}
