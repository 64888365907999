import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../shared/base.component';
import { UserService } from 'src/app/service/user.service';
import { switchMap, takeUntil } from 'rxjs';
import { Compte } from 'src/app/models/compte.model';
import { User } from 'src/app/models/user.model';
import { CompteService } from 'src/app/service/compte.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-compte',
  templateUrl: './compte.component.html',
  styleUrls: ['./compte.component.scss']
})
export class CompteComponent extends BaseComponent implements OnInit {

  comptes: Compte[] = [];
  currentUser?: User;

  constructor(private userService: UserService, private compteService: CompteService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.userService.getUserConnected().pipe(
      switchMap(user => {
        if (user?.id) {
          this.currentUser = user;
          return this.compteService.findAllByUserId(user.id);
        }
        return [];
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(comptes => this.comptes = comptes);
  }

  addCompte() {
    this.router.navigate(['/compte/create']);
  }

  modify(compte: Compte) {
    if (compte.type === 'ENTREPRISE') {
      this.router.navigate(['/compte/edit', compte.id]);
    }
  }
}
