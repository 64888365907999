<div class="flex flex-column team-content gap-2 mt-4">
  <div class="flex flex-row justify-content-between align-items-center ">
    <span class="block-title subtitle">Liste des équipes</span>
    <button class="p-button-success" pButton type="button" (click)="ajouter()">Ajouter</button>
  </div>
  <div class="flex flex-column flex-1">
    <p-table [value]="teams">
      <ng-template pTemplate="header">
        <tr>
          <th>Team</th>
          <th>Capitaine</th>
          <th class="th-nbm">Nb. membres</th>
          <th class="th-action"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-team>
        <tr>
          <td>{{ team.name }}</td>
          <td>{{ team.captain?.prenom }} {{ team.captain?.nom }}</td>
          <td>{{ team.members.length + 1 }}</td>
          <td class="icon-action"><em class="pi pi-file-edit"(click)="modifier(team)"></em></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr><td colspan="8">Aucune équipe</td></tr>
      </ng-template>
    </p-table>
  </div>
</div>
