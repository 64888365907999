
export class Commande {
  id?: number;
  numero?: string;
  idCompte?: number;
  numeroCompte?: string;
  typeCompte?: string;
  dateCommande?: string;
  prixTotal?: number;
  solde?: boolean;
  utilisateurName?: string;
  utilisateurPhone?: string;
  entreprise?: string;
  commandeLines: CommandeLines[] = [];
}

export class CommandeLines {
  id?: number;
  commandeId?: number;
  tarif?: Tarif;
}

export class Tarif {
  id?: number;
  prix?: number;
  nombre?: number;
  recursif?: boolean;
  description?: string;
}
