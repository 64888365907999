<div class="flex justify-content-center event-user-list flex-1">
  <div class="center-panel very-large form-panel ">
    <div><span class="block-title">Liste des événement utilisateur</span></div>
    <div class="separtor-line"></div>
    <div class="user-list mt-2">
      <p-table [value]="events">
        <ng-template pTemplate="header">
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Part.</th>
            <th>Créateur</th>
            <th>Date création</th>
            <th>Date démarrage</th>
            <th>Statut</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-event>
          <tr>
            <td>{{ event.name }}</td>
            <td>{{ event.type }}</td>
            <td>{{ event.nbParticipant }}</td>
            <td>{{ event.ownerName }}</td>
            <td>{{ event.eventDate | date: 'dd/MM/yyyy' }}</td>
            <td>{{ event.startDate | date: 'dd/MM/yyyy' }}</td>
            <td>
              <p-tag [value]="event.status === 'CREATED' ? 'Nouveau' : event.status === 'CONFIG' ? 'Configuration' : event.status === 'READY' ? 'Prêt' : event.status === 'STARTED' ? 'En cours' : 'Terminé'"
                     [severity]="(event.status === 'CREATED' || event.status === 'CONFIG' || event.status === 'READY') ? 'success' : event.status === 'STARTED' ? 'warning' : 'danger'"></p-tag>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr><td colspan="8">Aucun événement utilisateur</td></tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
