import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Event } from "../models/event.model";
import { User } from "../models/user.model";

@Injectable({providedIn: 'root'})
export class SuiviRightService {
  constructor(private http: HttpClient) {}

  findSuiviEvent(userId: number): Observable<Event[]> {
    return this.http.get<Event[]>('/suiviright/findSuiviEvent/' + userId);
  }

  findUserByEvent(eventId: number): Observable<User[]> {
    return this.http.get<User[]>('/suiviright/findUserByEvent/' + eventId);
  }

  addUser(users: User[], eventId: number): Observable<User[]> {
    return this.http.post<User[]>('/suiviright/addUser', {event: {id: eventId}, users});
  }
}
