import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthService } from "src/app/service/auth.service";
import { GlobalMessageService } from "src/app/service/globalMessage.service";

@Injectable()
export class HttpErrorFilter implements HttpInterceptor {

    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(private authService: AuthService,
                private globalMessageService: GlobalMessageService, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.globalMessageService.clearMessages();
        return next.handle(request).pipe(
            catchError((error: any) => {
                if (error.status === 401 && !request.url.includes('auth')) {
                    return this.handle401Error(request, next);
                }
                return throwError(() => this.handleErrorMessage(error));
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.includes('auth/refreshtoken')) {
            this.isRefreshingToken = false;
            return of(<any>this.authService.logout());
        }
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.tokenSubject.next('');

            return this.authService.refresh().pipe(switchMap(token => {
                if (token) {
                    this.tokenSubject.next(token);
                    return next.handle(request);
                }
                return of(<any>this.authService.logout());
            }),
            catchError(err => {
                this.authService.logout();
                return throwError(() => err.error);
            }),
            finalize(() => {
                this.isRefreshingToken = false;
            }));
        } else {
            this.isRefreshingToken = false;

            return this.tokenSubject
                .pipe(filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(request);
                }));
        }
    }

    private handleErrorMessage(error: any): any {
        this.globalMessageService.clearMessages();
        if (error.status === 403) {
          this.router.navigate(['/home']);
          this.globalMessageService.addErrorMessage('Vous n\'avez pas le droit d\'accéder à cette page');
        } else if (!error.error || error.error.message === undefined) {
            // this.authenticationService.logout();
            let message = 'Erreur inattendue s\'est produite, veuillez contacter votre administrateur';
            if (!(error.statusText === 'Unknown Error')) {
                message = error.message;
            }
            this.globalMessageService.addErrorMessage(message);
        } else if (error.error && error.error.message) {
          this.globalMessageService.addErrorMessage(error.error.message);
        } else {
            return error.error;
        }
    }
}
