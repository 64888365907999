import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Tarif } from 'src/app/models/commande.model';
import { TarifService } from 'src/app/service/tarif.service';

@Component({
  selector: 'app-tarif-dialog',
  templateUrl: './tarif-dialog.component.html',
  styleUrls: ['./tarif-dialog.component.scss']
})
export class TarifDialogComponent implements OnInit {

  constructor(private tarifService: TarifService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  tarifs: Tarif[] = [];
  selectedTarifs: Tarif[] = [];
  modeConsult: boolean = false;

  ngOnInit(): void {
    if (this.config.data) {
      this.modeConsult = this.config.data.modeConsult ?? false;
    }
    this.tarifService.findAll().subscribe(results => {
      this.tarifs = results;
      this.tarifs.sort((t1, t2) => t1.prix! - t2.prix!);
    });
  }

  add() {
    this.ref.close(this.selectedTarifs);
  }

  close() {
    this.ref.close();
  }

}
