<div class="news-content flew flew-column gap-3">
  <div *ngIf="isAdmin" class="flex flex-row justify-content-end">
    <button class="p-button-success" pButton type="button" (click)="add()">Ajouter une news</button>
  </div>
  <div class="flex flex-column gap-3">
    <div class="flex flex-column gap-2 pt-4" *ngFor="let news of newsList; let first = first" [ngClass]="{ 'border-top-1 surface-border': !first }">
      <div class="flex flex-row gap-3 align-items-end">
        <span class="block-title subtitle">{{ news.title }}</span>
        <span class="text-label">({{ news.publicationDate | date: 'dd/MM/yyyy HH:mm' }})</span>
        <em class="pi pi-file-edit edit-icon" *ngIf="isAdmin" pTooltip="Editer la news" tooltipPosition="bottom" [showDelay]="500" (click)="edit(news)"></em>
      </div>
      <div class="flex flex-column">
        <app-text-editor-viewer [innerText]="news.content ?? ''"></app-text-editor-viewer>
      </div>
    </div>
  </div>
</div>
