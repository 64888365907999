<div class="edit-ladder-step flex justify-content-center flex-1">
  <div class="edit-ladder-step-panel center-panel form-panel">
    <form [formGroup]="teamForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-column gap-3">
        <div class="flex flex-column gap-2 flex-1">
          <label class="required" htmlFor="name">Nom d'équipe</label>
          <input id="name" type="text" pInputText formControlName="name"/>
        </div>
        <div class="flex flex-column gap-2 flex-1">
          <label class="required" htmlFor="capitaine">Capitaine</label>
          <p-autoComplete formControlName="capitaine" [suggestions]="capitaineSuggestions" (completeMethod)="searchCapitaine($event)" [delay]="600" [field]="$any(getUserLabel)" placeholder="Rechercher">
            <ng-template let-user pTemplate="item">
              {{ user.prenom + ' ' + user.nom }}
            </ng-template>
          </p-autoComplete>
        </div>
        <app-user-list [label]="'Membres'" [(users)]="membres"></app-user-list>
      </div>
      <div class="separtor-line"></div>
      <div class="flex justify-content-end mt-3 gap-3">
        <button class="p-button-success" pButton type="submit" [disabled]="!teamForm.valid">{{mode === 'CREATE' ? 'Ajouter' : 'Enregistrer'}}</button>
      </div>
    </form>
  </div>
</div>
