import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Tarif } from 'src/app/models/commande.model';
import { TarifService } from 'src/app/service/tarif.service';

@Component({
  selector: 'app-tarif',
  templateUrl: './tarif.component.html',
  styleUrls: ['./tarif.component.scss']
})
export class TarifComponent implements OnInit {

  tarifs: Tarif[] = [];

  constructor(private tarifService: TarifService, private router: Router) {}

  ngOnInit(): void {
    this.tarifService.findAll().subscribe(results => {
      this.tarifs = results;
      this.tarifs.sort((t1, t2) => t1.prix! - t2.prix!);
    });
  }

  addTarif() {
    this.router.navigate(['/tarif/create']);
  }

  modify(tarif: Tarif) {
    this.router.navigate(['/tarif/edit', tarif.id]);
  }
}
