import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, debounceTime, of, switchMap, takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/component/shared/base.component';
import { EventPisteSteps } from 'src/app/models/event.model';
import { Team } from 'src/app/models/team.model';
import { EventService } from 'src/app/service/event.service';

@Component({
  selector: 'app-photo-card',
  templateUrl: './photo-card.component.html',
  styleUrls: ['./photo-card.component.scss']
})
export class PhotoCardComponent extends BaseComponent implements OnInit {
  @Input() step?: EventPisteSteps;
  @Input() team?: Team;
  @Input() placeholder?: string;
  @Input() started: boolean = false;

  @Output() stepChange = new EventEmitter<EventPisteSteps>();

  code: string = '';

  valueChanged: Subject<string> = new Subject<string>();

  constructor(private eventService: EventService){
    super();
  }

  ngOnInit(): void {
    this.valueChanged.pipe(
      debounceTime(600),
      switchMap(value => {
        if (this.step && this.team && value && value.length > 2) {
          return this.eventService.validedCode(this.step.id!, this.team.id!, value);
        }
        return of(undefined);
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(validStep => {
      if (validStep) {
        this.step!.code = validStep.code;
        this.step!.imageData!.data = validStep.imageData?.data;
        this.step!.imageData!.miniData = validStep.imageData?.miniData;
        this.stepChange.emit(validStep);
      }
    });
  }

  onChangeInput(text: string) {
    this.valueChanged.next(text);
  }
}
