<div class="flex flex-column create-event-dialog-content flex-1">
  <div>
    <p class="mt-0">Vous avez choisi la création d'un événement de type <strong>{{ typeEvent }}</strong> pour <strong>{{ nbParticipant }}</strong> participants. Vous allez être facturé du montant suivant :</p>
  </div>
  <div class="flex flex-1">
    <p-table [value]="nomtantEvent" dataKey="id" class="flex-grow-1">
      <ng-template pTemplate="header">
        <tr>
          <th>Evénement type</th>
          <th class="w-10rem">Nb participants</th>
          <th class="w-10rem">Prix</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-montant>
        <tr>
          <td>{{ montant.type }}</td>
          <td>{{ montant.nombre === 0 ? 'Illimité' : montant.nombre }}</td>
          <td>${{ montant.prix }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="flex justify-content-end mt-3 gap-2">
    <p-checkbox [(ngModel)]="checkedCGU" [binary]="true" inputId="binary" label="Accepter les conditions d'utilisation."
                [ngModelOptions]="{standalone: true}"></p-checkbox>
    <em class="pi pi-info-circle align-self-center text-label info-cgu" pTooltip="Voir les CGU" [showDelay]="500" (click)="displayCGU()"></em>
  </div>
  <div class="flex flex-column">
    <div class="separtor-line"></div>
    <div class="flex justify-content-end gap-3">
      <button class="p-button-success" pButton type="button" (click)="validate()" [disabled]="!checkedCGU">Oui</button>
      <button class="p-button-success" pButton type="button" (click)="close()">Non</button>
    </div>
  </div>
</div>
