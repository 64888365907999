import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { of, switchMap } from 'rxjs';
import { Tarif } from 'src/app/models/commande.model';
import { Compte } from 'src/app/models/compte.model';
import { Event } from 'src/app/models/event.model';
import { User } from 'src/app/models/user.model';
import { CompteService } from 'src/app/service/compte.service';
import { CreditService } from 'src/app/service/credit.service';
import { EventService } from 'src/app/service/event.service';
import { TarifService } from 'src/app/service/tarif.service';
import { UserService } from 'src/app/service/user.service';
import { LabelUtils } from 'src/app/shared/utils/label.utils';
import { CguDetailComponent } from '../../shared/cgu-detail/cgu-detail.component';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-copy-preset',
  templateUrl: './copy-preset.component.html',
  styleUrls: ['./copy-preset.component.scss']
})
export class CopyPresetComponent implements OnInit {

  currentUser?: User;
  comptes: Compte[] = [];
  participants: SelectItem<number>[] = [];
  nbParticipant: SelectItem<number> = {label: '', value: 0};
  creditCount: number = 0;
  compte?: Compte;
  preset?: Event;
  nomtantEvent: {type: string, nombre: number, prix: number}[] = [];
  checkedCGU: boolean = false;
  tarif?: Tarif;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private eventService: EventService, private tarifService: TarifService,
              private userService: UserService, private compteService: CompteService, private creditService: CreditService, private router: Router,
              public dialogService: DialogService) {
  }

  ngOnInit(): void {
    if (this.config.data) {
      this.preset = this.config.data.preset;
    }
    this.tarifService.getListNbPersonne().subscribe(results => {
      results.sort((n1, n2) => n2 === 0 ? -1 : n1 - n2);
      results.forEach(num => {
        this.participants.push({label: num === 0 ? 'Participants illimité' : num + ' participants', value: num});
      });
      if (this.participants.length > 0) {
        this.nbParticipant = this.participants[0];
      }
    });
    this.userService.getUserConnected().pipe(
      switchMap(user => {
        this.currentUser = user;
        return this.compteService.findAllByUserId(user?.id!);
      }),
    ).subscribe(comptes => {
      this.comptes = comptes;
      if (this.comptes.length > 0) {
        this.compte = this.comptes.find(c => c.type === 'PARTICULIER');
      }
      this.updateCredit();
    });
  }

  onCompteChange() {
    this.updateCredit();
  }

  onParticipantChange() {
    this.updateCredit();
  }

  displayCGU() {
    this.dialogService.open(CguDetailComponent, {header: "Conditions générales d'utilisation"});
  }

  copyPreset() {
    this.eventService.copyFromPreset(this.preset?.id!, this.compte?.id!, this.nbParticipant.value).subscribe(result => {
      this.close();
      this.router.navigate(['/event']);
    });
  }

  close() {
    this.ref.close(undefined);
  }

  private updateCredit() {
    if (this.compte && this.nbParticipant !== undefined) {
      this.nomtantEvent = [];
      this.creditService.countCredit(this.compte!.id!, this.nbParticipant.value).pipe(
        switchMap(count => {
          this.creditCount = count ?? 0;
          if (this.creditCount === 0) {
            return this.tarifService.findByNbParticipant(this.nbParticipant.value);
          } else {
            return of(undefined);
          }
        })
      ).subscribe(tarif => {
        this.tarif = tarif;
        if (tarif) {
          this.nomtantEvent.push({type: LabelUtils.eventTypeLabel(this.preset?.type!), nombre: this.nbParticipant.value, prix: this.tarif?.prix ?? 0});
        }
      });
    }
  }
}
