import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Team } from "../models/team.model";

@Injectable({providedIn: 'root'})
export class TeamService {
  constructor(private http: HttpClient) {}

  findByEventLadderId(eventLadderId: number): Observable<Team[]> {
    return this.http.get<Team[]>('/team/findByEventLadderId/' + eventLadderId);
  }

  findByUserIdEventLadderId(userId: number, eventLadderId: number): Observable<Team> {
    return this.http.get<Team>('/team/findByUserIdEventLadderId/' + userId + '/' + eventLadderId);
  }

  findByUserIdEventPisteId(userId: number, eventPisteId: number): Observable<Team> {
    return this.http.get<Team>('/team/findByUserIdEventPisteId/' + userId + '/' + eventPisteId);
  }

  save(team: Team): Observable<Team> {
    return this.http.post<Team>('/team/save', team);
  }

  createTeamForEventPiste(team: Team, eventPisteId: number): Observable<Team> {
    return this.http.post<Team>('/team/createTeamForEventPiste', {team, eventPisteId});
  }

  findByQueryEventPisteId(query: string, eventPisteId: number): Observable<Team[]> {
    return this.http.get<Team[]>('/team/findByQueryEventPisteId/' + query + '/' + eventPisteId);
  }

  addUserToTeam(userId: number, teamId: number): Observable<Team> {
    return this.http.get<Team>('/team/addUserToTeam/' + userId + '/' + teamId);
  }
}
