<div class="flex justify-content-center commande-content flex-1">
  <div class="center-panel large form-panel ">
    <div><span class="block-title">Liste de vos commandes</span></div>
    <div class="separtor-line"></div>
    <div class="flex justify-content-end gap-3">
      <button class="p-button-success" pButton type="button" (click)="addCommande()" [disabled]="!currentUser || !currentUser.validate">Nouvelle commande</button>
    </div>
    <div class="commande-list mt-2">
      <p-table [value]="commandes">
        <ng-template pTemplate="header">
          <tr>
            <th>Numéro</th>
            <th>Compte</th>
            <th>Type de compte</th>
            <th>date de commande</th>
            <th>Montant</th>
            <th>Payée</th>
            <th class="icon-action"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-commande>
          <tr>
            <td>{{ commande.numero }}</td>
            <td>{{ commande.numeroCompte }}</td>
            <td>{{ commande.typeCompte }}</td>
            <td>{{ commande.dateCommande | date: 'dd/MM/yyyy' }}</td>
            <td>${{ commande.prixTotal }}</td>
            <td>{{ commande.solde ? 'Oui' : 'Non' }}</td>
            <td class="icon-action"><em class="pi pi-eye"(click)="consulter(commande)"></em></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr><td colspan="7">Aucune commandes</td></tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
