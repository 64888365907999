import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { of, switchMap, takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/component/shared/base.component';
import { EventLadder, EventLadderStep } from 'src/app/models/event.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/service/auth.service';
import { EventService } from 'src/app/service/event.service';
import { EditLadderStepComponent } from '../edit-ladder-step/edit-ladder-step.component';

@Component({
  selector: 'app-ladder-step',
  templateUrl: './ladder-step.component.html',
  styleUrls: ['./ladder-step.component.scss']
})
export class LadderStepComponent extends BaseComponent implements OnInit {
  types: {value: string, label: string}[] = [{value: 'TIME', label: 'Temps'}, {value: 'POINT', label: 'Point'}];
  typeClassement?: string;
  steps: EventLadderStep[] = [];

  currentUser?: User;
  eventLadder?: EventLadder;

  constructor(private eventService: EventService, private authService: AuthService, private route: ActivatedRoute, private router: Router,
              private messageService: MessageService, public dialogService: DialogService) {
    super();
  }

  ngOnInit(): void {
    this.authService.getConnectedUser().pipe(
      switchMap(user => {
        this.currentUser = user;
        return this.route.params;
      }),
      switchMap(params => {
        if (params['id'] && this.currentUser?.id) {
          return this.eventService.findLadderByEventIdUserId(Number(params['id']), this.currentUser.id);
        }
        return of(undefined);
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(eventLadder => {
      if (eventLadder) {
        this.eventLadder = eventLadder;
        this.steps = [...this.eventLadder.steps ?? []];
      } else {
        this.messageService.add({ severity: 'error', summary: 'Evénement', detail: 'l\'événement de classement n\'existe pas ou vous n\'avez pas les droits dessus.' });
        this.router.navigate(['/event']);
      }
    });
  }

  ajouter() {
    const ref: DynamicDialogRef = this.dialogService.open(EditLadderStepComponent, {
      header: 'Ajouter une étape',
      data: {
        mode: 'CREATE',
        eventLadder: this.eventLadder,
        type: this.typeClassement,
      }
    });

    ref.onClose.subscribe((eventStep: EventLadderStep) => {
      if (eventStep) {
        this.eventLadder?.steps?.push(eventStep);
        this.steps = [...this.eventLadder?.steps ?? []];
        this.messageService.add({ severity: 'success', summary: 'Créé', detail: 'l\'étape de l\'événement a été ajouté.' });
      }
    });
  }

  modifier(eventStep: EventLadderStep) {
    const ref: DynamicDialogRef = this.dialogService.open(EditLadderStepComponent, {
      header: "Edition de l'étape",
      data: {
        mode: 'EDIT',
        eventLadder: this.eventLadder,
        type: this.typeClassement,
        stepId: eventStep.id,
      }
    });

    ref.onClose.subscribe((eventStep: EventLadderStep) => {
      if (eventStep) {
        this.eventLadder!.steps = [...this.eventLadder?.steps?.filter(s => s.id !== eventStep.id) ?? [], eventStep];
        this.eventLadder!.steps.sort((s1, s2) => s1.ordre! - s2.ordre!);
        this.messageService.add({ severity: 'success', summary: 'Modifié', detail: 'l\'étape de l\'événement a été modifié.' });
      }
    });
  }
}
