<div class="event-piste-score">
  <p-table [value]="eventPisteScores">
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between">
          Scores des participants
          <p-button icon="pi pi-refresh" (onClick)="refreshScores()" />
      </div>
  </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Participant</th>
        <th *ngFor="let stepTime of columns" class="step-time-th">{{ stepTime }}</th>
        <th class="total-time-th">Temps total</th>
        <th class="icon-action"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-score>
      <tr>
        <td>{{ score.solo ? score.userName : score.teamName }}</td>
        <td *ngFor="let stepTime of score.stepTimes">{{ stepTime }}</td>
        <td>{{ score.totalTime }}</td>
        <td class="icon-action">
          <em class="pi pi-check-circle" pTooltip="Valider la dernière étape" [showDelay]="500" (click)="valider(score)"></em>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr><td colspan="8">Aucun scores</td></tr>
    </ng-template>
  </p-table>
</div>
