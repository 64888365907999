<div class="user-list flex flex-column flex-1 gap-3">
  <div class="flex flex-row align-items-center gap-4">
    <div class="flex flex-column gap-2 flex-1">
      <label htmlFor="name">{{ label }}</label>
      <p-autoComplete [(ngModel)]="selectedUser" [suggestions]="suggestions" (completeMethod)="search($event)" [delay]="600" [field]="$any(getUserLabel)" placeholder="Rechercher">
        <ng-template let-user pTemplate="item">
          {{ user.prenom + ' ' + user.nom }}
        </ng-template>
      </p-autoComplete>
    </div>
    <div class="align-self-end">
      <button pButton type="button" (click)="addUser()" [disabled]="!selectedUser">Ajouter</button>
    </div>
  </div>
  <div>
    <p-table [value]="innerUsers">
      <ng-template pTemplate="header">
        <tr>
          <th>Prénom</th>
          <th>Nom</th>
          <th class="icon-action"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td>{{ user.prenom }}</td>
          <td>{{ user.nom }}</td>
          <td class="icon-action"><em class="pi pi-trash" (click)="remove(user)"></em></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr><td colspan="3">Aucun utilisateur</td></tr>
      </ng-template>
    </p-table>
  </div>
</div>
