<div class="play-ladder flex justify-content-center flex-1">
  <div class="center-panel very-large form-panel">
    <div class="flex flex-row align-items-center gap-3">
      <span class="block-title flex-grow-1">Evénements de classement : {{ eventLadder?.event?.name }}</span>
      <p-tag [value]="eventLadder?.event?.status === 'STARTED' ? 'En cours' : eventLadder?.event?.status === 'FINISHED' ? 'Terminé' : 'En attente'"
                         [severity]="eventLadder?.event?.status === 'STARTED' ? 'success' : eventLadder?.event?.status === 'FINISHED' ? 'danger' : 'warning'"></p-tag>
      <em *ngIf="!isManager" class="pi pi-refresh icon-action" (click)="refreshStatus()"
          pTooltip="Mettre à jour l'événement" tooltipPosition="bottom" [showDelay]="500"></em>
      <em *ngIf="isManager && eventLadder?.event?.status === 'READY'" class="pi pi-play icon-action" (click)="startEvent()"
          pTooltip="Démarrer l'événement" tooltipPosition="bottom" [showDelay]="500"></em>
      <em *ngIf="isManager && eventLadder?.event?.status === 'STARTED'" class="pi pi-stop-circle icon-action" (click)="endEvent()"
          pTooltip="Terminer l'événement" tooltipPosition="bottom" [showDelay]="500"></em>
    </div>
    <div class="separtor-line"></div>
    <div class="flex flex-column gap-5 mt-5">
      <app-team *ngIf="isManager && eventLadder?.event?.status === 'READY'" [eventLadder]="eventLadder" (addTeamChange)="addTeam($event)"></app-team>
      <app-ladder-checkpoint *ngIf="isManager && eventLadder?.event?.status === 'STARTED'" [eventLadder]="eventLadder"></app-ladder-checkpoint>
      <app-ladder-suivi *ngIf="!isManager && eventLadder?.event?.status === 'STARTED'" [eventLadder]="eventLadder" [user]="currentUser"></app-ladder-suivi>
    </div>
  </div>
</div>
