<div class="create-compte flex justify-content-center flex-1">
  <div class="create-compte-panel center-panel large form-panel">
    <div><span class="block-title">{{ compte?.id ? 'Edition du compte' : 'Nouveau compte' }}</span></div>
    <div class="separtor-line"></div>
    <form [formGroup]="compteForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-column gap-3">
        <div class="flex flex-row align-items-center gap-4 flex-1">
          <div class="flex flex-column gap-2">
            <label class="required" htmlFor="type">Type</label>
            <input id="type" type="text" pInputText formControlName="type" readonly="true"/>
          </div>
          <div class="flex flex-column gap-2 flex-1">
            <label class="required" htmlFor="name">Nom de l'entrprise</label>
            <input id="name" type="text" pInputText formControlName="name"/>
          </div>
          <div class="flex flex-column gap-2">
            <label htmlFor="numero">Numéro administratif</label>
            <input id="numero" type="text" pInputText formControlName="numero"/>
          </div>
        </div>
        <div class="flex flex-row align-items-start gap-4 flex-1">
          <div class="flex flex-column gap-2 flex-grow-1">
            <label class="required" htmlFor="responsable">Responsable</label>
            <p-autoComplete formControlName="responsable" [suggestions]="suggestions" (completeMethod)="search($event)" [delay]="600" [field]="$any(getUserLabel)" placeholder="Rechercher">
              <ng-template let-user pTemplate="item">
                {{ user.prenom + ' ' + user.nom }}
              </ng-template>
            </p-autoComplete>
          </div>
          <app-user-list [label]="'Collaborateurs'" [(users)]="collaborateurs"></app-user-list>
        </div>
        <div class="flex flex-row align-items-start gap-4 flex-1">

        </div>
      </div>
      <div class="separtor-line"></div>
      <div class="flex justify-content-end mt-3 gap-3">
        <button class="p-button-success" pButton type="submit" [disabled]="!compteForm.valid">Sauvegarder</button>
      </div>
    </form>
  </div>
</div>
