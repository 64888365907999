<div class="flex flex-column gap-3 text-editor-content">
  <div class="flex flex-column gap-2">
    <div class="flex flex-column">
      <p-editor [(ngModel)]="text" [style]="{ height: '320px' }" />
    </div>
    <div class="flex flex-row justify-content-between">
      <input id="name" [(ngModel)]="title" type="text" placeholder="Titre" pInputText/>
      <div class="flex flex-row gap-3">
        <button class="p-button-success" pButton type="button" (click)="preview()">Prévisualiser</button>
        <button class="p-button-success" pButton type="button" (click)="save()">{{ newsToModify ? 'Modifier' : 'Ajouter' }}</button>
        <button class="p-button-success" pButton type="button" (click)="cancel()">Annuler</button>
      </div>
    </div>
  </div>
  <app-text-editor-viewer class="mt-3" [innerText]="previousText"></app-text-editor-viewer>
</div>
