import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { dialogMode } from "src/app/models/data.model";
import { Team } from "src/app/models/team.model";
import { User } from "src/app/models/user.model";
import { TeamService } from "src/app/service/team.service";
import { UserService } from "src/app/service/user.service";

@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.scss']
})
export class EditTeamComponent implements OnInit {
  teamForm = this.fb.group({
    name: ['', Validators.required],
    solo: [''],
    capitaine: new FormControl<User | undefined>(undefined, Validators.required),
    members: [''],
  });

  mode: dialogMode = 'CREATE';
  capitaineSuggestions: User[] = [];
  membres: User[] = [];
  team?: Team;

  constructor(private fb: FormBuilder, public ref: DynamicDialogRef, public config: DynamicDialogConfig,
              private userService: UserService, private teamService: TeamService) {
  }

  ngOnInit(): void {
    if (this.config.data) {
      if (this.config.data.mode === 'CREATE' && this.config.data.numberTeam != undefined) {
        this.teamForm.get('name')?.setValue('Team ' + (this.config.data.numberTeam + 1));
      } else if (this.config.data.mode === 'EDIT' && this.config.data.team) {
        this.mode = this.config.data.mode;
        this.team = this.config.data.team;
        this.membres = this.team?.members ?? [];
        this.teamForm.patchValue({
          capitaine: this.config.data.team.captain,
          members: this.config.data.team.members,
          name: this.config.data.team.name,
          solo: this.config.data.team.solo,
        });
      }
    }
  }

  getUserLabel(user: User) {
    if (user) {
      return user.prenom + ' ' + user.nom;
    }
    return '';
  }

  searchCapitaine(event: any) {
    if (event.query) {
      this.userService.findByNomPrenom(event.query).subscribe(results => this.capitaineSuggestions = [...results]);
    }
  }

  onSubmit() {
    if (this.teamForm.valid) {
      let teamToSave = new Team();
      if (this.team) {
        teamToSave = {...this.team};
      }
      teamToSave.name = this.teamForm.get('name')?.value ?? '';
      teamToSave.captain = this.teamForm.get('capitaine')?.value ?? undefined;
      teamToSave.members = this.membres;
      this.teamService.save(teamToSave).subscribe(result =>  {
        this.ref.close(result);
      });
    }
  }
}
