<div class="flex flex-column tarif-dialog-content flex-1">
  <div class="flex flex-1">
    <p-table [value]="tarifs" [(selection)]="selectedTarifs" dataKey="id" class="flex-grow-1">
      <ng-template pTemplate="header">
        <tr>
          <th class="w-4rem" *ngIf="!modeConsult">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th>Description</th>
          <th class="w-10rem">Nb participants</th>
          <th class="w-10rem">Prix</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-tarif>
        <tr>
          <td *ngIf="!modeConsult">
            <p-tableCheckbox [value]="tarif"></p-tableCheckbox>
          </td>
          <td>{{ tarif.description }}</td>
          <td>{{ tarif.nombre === 0 ? 'Illimité' : tarif.nombre }}</td>
          <td>${{ tarif.prix }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr><td colspan="5">Aucun tarifs</td></tr>
      </ng-template>
    </p-table>
  </div>
  <div class="separtor-line"></div>
  <div class="flex justify-content-end mt-2 gap-3">
    <button *ngIf="!modeConsult" class="p-button-success" pButton type="button" (click)="add()" [disabled]="selectedTarifs.length === 0">Ajouter</button>
    <button *ngIf="modeConsult" class="p-button-success" pButton type="button" (click)="close()">Fermer</button>
  </div>
</div>
