import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { ImageData, dialogMode } from 'src/app/models/data.model';
import { EventPiste, EventPisteSteps } from 'src/app/models/event.model';
import { EventService } from 'src/app/service/event.service';

@Component({
  selector: 'app-edit-jeu-piste',
  templateUrl: './edit-jeu-piste.component.html',
  styleUrls: ['./edit-jeu-piste.component.scss']
})
export class EditJeuPisteComponent implements OnInit {

  @ViewChild('imageUpload') public imageUpload?: FileUpload;

  pisteForm = this.fb.group({
    code: ['', Validators.required],
    indice: [''],
    imageName: ['', Validators.required],
  });

  mode: dialogMode = 'CREATE';
  eventPiste?: EventPiste;
  imageName?: string;
  imageType?: string;
  imageDataUrl?: string;
  uploadedFile?: File;
  eventStep?: EventPisteSteps;

  constructor(private fb: FormBuilder, public ref: DynamicDialogRef, public config: DynamicDialogConfig, private eventService: EventService) {
  }

  ngOnInit(): void {
    if (this.config.data) {
      this.mode = this.config.data.mode;
      if (this.config.data.eventPiste) {
        this.eventPiste = this.config.data.eventPiste;
      }
      if (this.config.data.step) {
        this.eventService.findPisteStep(this.config.data.step.id).subscribe(result => {
          this.eventStep = result;
          this.pisteForm.patchValue({
            code: this.eventStep.code,
            indice: this.eventStep.indice,
            imageName: this.eventStep.imageData?.imageName,
          });
          this.imageDataUrl = this.eventStep.imageData?.data;
          this.imageName = this.eventStep.imageData?.imageName;
          this.imageType = this.eventStep.imageData?.imageType;
        });
      }
    }
  }

  onSubmit() {
    if (this.pisteForm.valid) {
      let eventStep: EventPisteSteps;
      if (this.mode === 'CREATE') {
        eventStep = new EventPisteSteps();
        eventStep.ordre = (this.eventPiste?.steps?.length ?? 0) + 1;
        eventStep.eventPisteId = this.eventPiste?.id;
      } else {
        eventStep = {...this.eventStep};
      }
      eventStep.code = this.pisteForm.get('code')?.value ?? '';
      eventStep.indice = this.pisteForm.get('indice')?.value ?? '';
      if (!eventStep.imageData) {
        eventStep.imageData = new ImageData();
      }
      eventStep.imageData.data = this.imageDataUrl;
      eventStep.imageData.imageName = this.imageName;
      eventStep.imageData.imageType = this.imageType;
      this.eventService.savePisteStep(eventStep).subscribe(stepSaved => {
        this.pisteForm.reset();
        this.imageDataUrl = undefined;
        this.imageName = undefined;
        this.imageType = undefined;
        this.ref.close(stepSaved);
      });
    }
  }

  uploadFileHandler(event: any) {
    if (this.imageUpload && event && event.files && event.files.length > 0) {
      this.imageName = event.files[0].name;
      this.imageType = event.files[0].type;
      this.uploadedFile = event.files[0];
      this.imageUpload.clear();
      const reader = new FileReader();
      reader.readAsDataURL(event.files[0]);
      reader.onload = (() => {
        this.imageDataUrl =  reader.result?.toString();
        this.pisteForm.get('imageName')?.setValue(this.imageName!);
      });
    }
  }
}
