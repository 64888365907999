<div class="flex flex-column checkpoint-table-content">
  <p-table
      #tt dataKey="id"
      [value]="teamScores"
      [globalFilterFields]="['position', 'teamName', 'captainName']">
    <ng-template pTemplate="caption">
      <div class="flex flex-row justify-content-between">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="globalFilter(tt, $event)" placeholder="Rechercher" />
        </span>
        <p-button icon="pi pi-refresh" (onClick)="refreshScores()" />
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th class="th-position">Position</th>
        <th>Team</th>
        <th>Capitaine</th>
        <th class="th-last-step">Dernière étape validée</th>
        <th class="th-score">{{ type === 'TIME' ? 'Temps' : 'Points'}}</th>
        <th class="th-total">{{ type === 'TIME' ? 'Temps total' : 'Points total' }}</th>
        <th class="th-action"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-score>
      <tr>
        <td>{{ score.position }}</td>
        <td>{{ score.teamName }}</td>
        <td>{{ score.captainName }}</td>
        <td>{{ score.stepNames.length > 0 ? score.stepNames[(score.stepNames.length - 1)] : 'Etape 1' }}</td>
        <td *ngIf="type === 'TIME'">{{score.stepTimes.length > 0 ? score.stepTimes[(score.stepTimes.length - 1)] : '00:00:00' }}</td>
        <td *ngIf="type === 'POINT'">{{score.stepPoints.length > 0 ? score.stepPoints[(score.stepPoints.length - 1)] : '0' }}</td>
        <td>{{ type === 'TIME' ? score.totalTime : score.totalPoint }}</td>
        <td class="td-action"><em *ngIf="!score.finished" class="pi pi-check-circle" pTooltip="Valider l'étape suivante" [showDelay]="500" (click)="valider(score)"></em></td>
      </tr>
    </ng-template>
  </p-table>
</div>
