import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { of, switchMap, takeUntil } from 'rxjs';
import { Tarif } from 'src/app/models/commande.model';
import { TarifService } from 'src/app/service/tarif.service';
import { BaseComponent } from '../../shared/base.component';

@Component({
  selector: 'app-create-tarif',
  templateUrl: './create-tarif.component.html',
  styleUrls: ['./create-tarif.component.scss']
})
export class CreateTarifComponent extends BaseComponent implements OnInit {
  tarif?: Tarif;
  mode: 'CREATE' | 'EDIT' = 'CREATE';

  tarifForm = this.fb.group({
    prix: new FormControl<number>(0, Validators.required),
    nombre: new FormControl<number>(0, Validators.required),
    recursif: new FormControl<boolean>(false),
    description: ['', Validators.required],
  });

  constructor(private fb: FormBuilder, private tarifService: TarifService, private messageService: MessageService,
      private route: ActivatedRoute, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.route.params.pipe(
      switchMap(params => {
        if (params['id']) {
          this.mode = 'EDIT';
          return this.tarifService.findById(params['id']);
        }
        return of(undefined);
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(tarif => {
      if (tarif) {
        this.tarif = tarif;
        this.updateForm(tarif);
      }
    });
  }

  onSubmit() {
    if(this.tarifForm.valid) {
      this.tarifService.save(this.formToTarif()).subscribe(tarifSaved => {
        this.tarif = tarifSaved;
        this.messageService.add({ severity: 'success', summary: 'Enregister', detail: 'le tarif à bien été enregister' });
        this.router.navigate(['/tarif']);
      });
    }
  }

  private updateForm(tarif: Tarif) {
    this.tarifForm.patchValue({
      prix: tarif.prix,
      nombre: tarif.nombre,
      recursif: tarif.recursif,
      description: tarif.description,
    });
  }

  private formToTarif(): Tarif {
    const tarifToSave = new Tarif();

    if (this.tarif) {
      tarifToSave.id = this.tarif.id;
    }

    if (this.tarifForm.get('prix')?.value) {
      tarifToSave.prix = Number(this.tarifForm.get('prix')?.value);
    }
    if (this.tarifForm.get('nombre')?.value) {
      tarifToSave.nombre = Number(this.tarifForm.get('nombre')?.value);
    }
    tarifToSave.recursif = this.tarifForm.get('recursif')?.value ?? false;
    tarifToSave.description = this.tarifForm.get('description')?.value ?? '';

    return tarifToSave;
  }
}
