<div class="jeu-piste-event flex justify-content-center flex-1">
  <div class="create-event-panel center-panel large form-panel">
    <div><span class="block-title">Etapes du jeu de piste</span></div>
    <div class="separtor-line"></div>
    <div class="flex justify-content-end gap-3">
      <button class="p-button-success" pButton type="button" (click)="addStep()">Ajouter</button>
      <button class="p-button-success" pButton type="button" (click)="addPreset()">Preset</button>
      <button class="p-button-success" pButton type="button" (click)="openEvents()">Terminer</button>
    </div>
    <div class="grid mt-2">
      <div class="col-6" *ngFor="let step of eventPiste?.steps">
        <p-card>
          <ng-template pTemplate="title">
            <div class="flex justify-content-between">
              <div class="code">Code : {{ step.code }}</div>
              <div><em class="pi pi-file-edit" (click)="editStep(step)"></em></div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <img src="{{ step.imageData?.miniData }}" />
          </ng-template>
          <p>Indice : {{ step.indice }}</p>
        </p-card>
      </div>
    </div>
  </div>
</div>
