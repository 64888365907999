import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Commande } from "../models/commande.model";

@Injectable({providedIn: 'root'})
export class CommandeService {
  constructor(private http: HttpClient) {}

  findAllByUserId(userId: number): Observable<Commande[]> {
    return this.http.get<Commande[]>('/commande/findAll/' + userId);
  }

  findFactures(): Observable<Commande[]> {
    return this.http.get<Commande[]>('/commande/findFactures');
  }

  findFacturesByDate(month: number, year: number, cancel: boolean): Observable<Commande[]> {
    return this.http.get<Commande[]>('/commande/findFacturesByDate/' + month + '/' + year + '/' + cancel);
  }

  save(commande: Commande): Observable<Commande> {
    return this.http.post<Commande>('/commande/save', commande);
  }

  validePaiement(id: number): Observable<Commande> {
    return this.http.get<Commande>('/commande/validePaiement/' + id);
  }

  cancelCommande(id: number): Observable<Commande> {
    return this.http.get<Commande>('/commande/cancelCommande/' + id);
  }
}
