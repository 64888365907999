import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Compte } from "../models/compte.model";

@Injectable({providedIn: 'root'})
export class CompteService {
  constructor(private http: HttpClient) {}

  findAllByUserId(userId: number): Observable<Compte[]> {
    return this.http.get<Compte[]>('/compte/findAll/' + userId);
  }

  findAllByUserIdAndActif(userId: number): Observable<Compte[]> {
    return this.http.get<Compte[]>('/compte/findAllActif/' + userId);
  }

  findById(id: number): Observable<Compte> {
    return this.http.get<Compte>('/compte/findById/' + id);
  }

  save(compte: Compte): Observable<Compte> {
    return this.http.post<Compte>('/compte/save', compte);
  }
}
