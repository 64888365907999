import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { takeUntil } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/service/user.service';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../shared/base.component';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { LabelUtils } from 'src/app/shared/utils/label.utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {
  private apiUrl = environment.apiUrl;
  private clientUri = environment.clientUri;

  @Input() sidebarMenu: boolean = false;
  @Output() sidebarMenuChange = new EventEmitter<boolean>();

  user?: User;
  username?: string;
  authUrl: string = this.apiUrl + '/oauth2/authorization/discord?redirect_uri=' + this.clientUri;
  avatarBaseUrl: string = 'https://cdn.discordapp.com/avatars/';
  avatarUrl: string = '';
  hasAvatar: boolean = true;
  firstNameLetter?: string;

  constructor(private userService: UserService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.userService.getUserConnected().pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      this.user = user;
      if (user) {
        this.username = LabelUtils.toCamelCase(user?.name ?? '');
        this.avatarUrl = this.avatarBaseUrl + user?.discordId + '/' + user?.avatar + '?size=32';
        if (!user.avatar) {
          this.hasAvatar = false
          this.firstNameLetter = user.name?.substring(0, 1).toLocaleUpperCase();
        }
      }
    });
  }

  login() {
    window.location.href = this.authUrl;
  }

  openMenu() {
    this.sidebarMenuChange.emit(true);
  }

  goHome() {
    this.router.navigate(['/home']);
  }
}
