import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { EventPiste } from "src/app/models/event.model";
import { Team } from "src/app/models/team.model";
import { User } from "src/app/models/user.model";
import { InscriptionPisteTeamComponent } from "./inscription-piste-team/inscription-piste-team.component";

@Component({
  selector: 'app-inscription-piste',
  templateUrl: './inscription-piste.component.html',
  styleUrls: ['./inscription-piste.component.scss']
})
export class InscriptionPisteComponent {
  @Input() eventPiste?: EventPiste;
  @Input() currentUser?: User;
  @Input() isManager: boolean = false;

  @Output() onCreateTeam = new EventEmitter<Team>();
  @Output() onJoinTeam = new EventEmitter<Team>();
  @Output() onManager = new EventEmitter<boolean>();

  constructor(public dialogService: DialogService) {}

  manager() {
    if (this.eventPiste?.event?.id) {
      localStorage.setItem('event.manager', String(this.eventPiste.event.id));
      this.onManager.emit(true);
    }
  }

  solo(){
    const team = new Team();
    team.captain = this.currentUser;
    team.name = 'team ' + this.currentUser?.prenom + ' ' + this.currentUser?.nom;
    team.solo = true;
    this.onCreateTeam.emit(team);
  }

  createTeam() {
    const ref: DynamicDialogRef = this.dialogService.open(InscriptionPisteTeamComponent, {
      header: 'Créer une nouvelle équipe',
      data: {
        mode: 'CREATE',
        user: this.currentUser,
        eventPiste: this.eventPiste
      }
    });

    ref.onClose.subscribe((team: Team) => {
      if (team) {
        this.onCreateTeam.emit(team);
      }
    });
  }

  joinTeam() {
    const ref: DynamicDialogRef = this.dialogService.open(InscriptionPisteTeamComponent, {
      header: 'Rejoindre une équipe',
      data: {
        mode: 'ADD',
        user: this.currentUser,
        eventPiste: this.eventPiste
      }
    });

    ref.onClose.subscribe((team: Team) => {
      if (team) {
        this.onJoinTeam.emit(team);
      }
    });
  }
}
