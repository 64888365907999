<div class="flex flex-column config-detail-content flex-1">
  <div>
    <p [innerHTML]="description"></p>
    <p>Pour cet événement il y a actuellement <b>{{ configDetail?.stepCount }}</b> étapes de configurées.</p>
  </div>
  <div class="flex flex-column">
    <div class="separtor-line"></div>
    <div class="flex justify-content-end gap-3">
      <button class="p-button-success" pButton type="button" (click)="close()">Fermer</button>
    </div>
  </div>
</div>
