<div class="insc-piste-team flex justify-content-center flex-1">
  <div class="insc-piste-team-panel center-panel  form-panel">
    <div class="flex flex-column">
      <div *ngIf="mode === 'CREATE'" class="flex flex-column gap-2 flex-1">
        <label class="required" htmlFor="name">Nom d'équipe</label>
        <input id="name" type="text" pInputText [(ngModel)]="teamName" (ngModelChange)="isTeamValid()"/>
      </div>
      <div *ngIf="mode === 'ADD'" class="flex flex-column gap-2 flex-1">
        <label class="required" htmlFor="team">Rechercher une équipe</label>
        <p-autoComplete [(ngModel)]="team" (ngModelChange)="isTeamValid()" [suggestions]="teamSuggestions" (completeMethod)="searchTeam($event)" [delay]="600" field="name" placeholder="Rechercher">
          <ng-template let-team pTemplate="item">
            {{ team.name }}
          </ng-template>
        </p-autoComplete>
      </div>
    </div>
    <div class="separtor-line"></div>
    <div class="flex justify-content-end mt-3 gap-3">
      <button class="p-button-success" pButton type="button" [disabled]="!teamValid" label="{{mode === 'CREATE' ? 'Créer' : 'Rejoindre'}}" (click)="valider()"></button>
      <button class="p-button-success" pButton type="button" (click)="close()">Fermer</button>
    </div>
  </div>
</div>
