<div class="event-header flex align-items-center">
  <div class="header-title flex flex-initial align-items-center"><img src="../../../assets/images/Logo_32.png" /><span (click)="goHome()">EventDoo</span></div>
  <div class="flex-grow-1"></div>
  <div class="flex-initial flex align-items-center">
    <div class="header-login" *ngIf="!user" (click)="login()"><span>Connexion</span></div>
    <div class="header-user" *ngIf="user">
      <span (click)="openMenu()">
        <span class="not-validate-span" pTooltip="Merci de compléter votre profile" tooltipPosition="left" [showDelay]="500"
              tooltipStyleClass="not-validate-text"><em *ngIf="!user.validate" class="pi pi-exclamation-triangle not-validate"></em></span>
        <img *ngIf="hasAvatar" pTooltip="{{ username }}" tooltipPosition="left" [showDelay]="500" tooltipZIndex="100001" src="{{ avatarUrl }}" />
        <div *ngIf="!hasAvatar" class="no-avatar"><span>{{ firstNameLetter }}</span></div>
      </span>
    </div>
  </div>
</div>
