<div class="flex justify-content-center presets-content flex-1">
  <div class="center-panel large form-panel ">
    <div><span class="block-title">Liste des présets d'événements</span></div>
    <div class="separtor-line"></div>
    <div class="event-list">
      <p-dataView [value]="events">
        <ng-template let-events pTemplate="list">
          <div class="flex flex-column flex-grow-1" *ngFor="let event of events; let first = first" [ngClass]="{ 'border-top-1 surface-border': !first }">
            <div class="flex flex-row align-items-start gap-4 py-3 flex-1">
              <img src="{{ event.imageData?.data ?? defaultImageUri }}" class="event-image" />
              <div class="flex flex-column align-items-start flex-1 gap-1">
                <div class="flex flex-row gap-4 align-items-center flex-1">
                  <div class="event-title">{{ event.name }}</div>
                </div>
                <div class="block-text-justify"><p>{{ event.description }}</p></div>
              </div>
              <div class="flex flex-column align-items-center gap-3 h-full">
                <div class="event-text">{{ event.eventDate | date : 'dd/MM/yyyy' }}</div>
                <div class="flex-grow-1"></div>
                <div class="flex flex-row gap-2 event-action">
                  <button class="p-button-success" pButton type="button" pTooltip="Créer un événement à partir de ce préset" (click)="copy(event)">Copier</button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template let-product pTemplate="empty"><div class="event-text">Il n'y a aucun préset d'événement</div></ng-template>
      </p-dataView>
    </div>
  </div>
</div>
