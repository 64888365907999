import { SuiviRightService } from './../../../service/suivi-right.service';
import { Component, OnInit } from '@angular/core';
import { Event, EventPiste, EventLadder } from 'src/app/models/event.model';
import { User } from 'src/app/models/user.model';
import { BaseComponent } from '../../shared/base.component';
import { AuthService } from 'src/app/service/auth.service';
import { of, switchMap, takeUntil } from 'rxjs';
import { EventService } from 'src/app/service/event.service';

@Component({
  selector: 'app-score-board',
  templateUrl: './score-board.component.html',
  styleUrl: './score-board.component.scss'
})
export class ScoreBoardComponent extends BaseComponent implements OnInit {
  currentUser?: User;
  events: Event[] = [];
  event?: Event;
  eventPiste?: EventPiste;
  eventLadder?: EventLadder;

  constructor(private authService: AuthService, private suiviRightService: SuiviRightService, private eventService: EventService) {
    super();
  }

  ngOnInit(): void {
    this.authService.getConnectedUser().pipe(
      switchMap(user => {
        this.currentUser = user;
        if (this.currentUser?.id) {
          return this.suiviRightService.findSuiviEvent(this.currentUser?.id);
        }
        return of(undefined);
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(events => {
      if (events) {
        this.events = events;
        this.event = events.find(e => e.status === 'STARTED');
        if (!this.event) {
          this.event = events.find(e => e.status === 'READY');
        }
        this.selectEventType(this.event);
      }
    });
  }

  onEventChange() {
    this.selectEventType(this.event);
  }

  private selectEventType(event?: Event) {
    if (event && event.code) {
      this.eventPiste = undefined;
      this.eventLadder = undefined;
      if (event.type === 'JEU_PISTE_IMAGE') {
        this.eventService.findJeuPisteToPlayByCode(event.code).subscribe(eventPiste => this.eventPiste = eventPiste);
      } else if (event.type === 'LADDER') {
        this.eventService.findLadderToPlayByCode(event.code).subscribe(eventLadder => this.eventLadder = eventLadder);
      }
    }
  }
}
