import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { of, switchMap, takeUntil } from "rxjs";
import { User } from "src/app/models/user.model";
import { AuthService } from "src/app/service/auth.service";
import { EventService } from "src/app/service/event.service";
import { BaseComponent } from "../../shared/base.component";
import { EventLadder } from "src/app/models/event.model";
import { Team } from "src/app/models/team.model";

@Component({
  selector: 'app-ladder',
  templateUrl: './ladder.component.html',
  styleUrls: ['./ladder.component.scss']
})
export class LadderComponent extends BaseComponent implements OnInit {
  currentUser?: User;
  eventLadder?: EventLadder;
  isManager: boolean = false;

  constructor(private eventService: EventService, private authService: AuthService,
    private route: ActivatedRoute, private router: Router, private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    this.authService.getConnectedUser().pipe(
      switchMap(user => {
        this.currentUser = user;
        return this.route.params;
      }),
      switchMap(params => {
        if (this.currentUser?.id) {
          if (params['id']) {
            return this.eventService.findLadderToPlay(Number(params['id']), this.currentUser.id);
          } else if (params['code']) {
            return this.eventService.findLadderToPlayByCode(params['code']);
          }
        }
        return of(undefined);
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(eventLadder => {
      if (eventLadder) {
        this.eventLadder = eventLadder;
        this.isManager = (eventLadder.event?.ownerId === this.currentUser?.id || eventLadder.event?.managers?.some(m => m.id === this.currentUser?.id)) ?? false;
        this.eventLadder.steps?.sort((s1, s2) => (s1.ordre && s2.ordre) ? s1.ordre - s2.ordre : 0);
      } else {
        this.messageService.add({ severity: 'error', summary: 'Evénement', detail: 'l\'événement de classement n\'existe pas ou vous n\'avez pas le droit d\'y assister.' });
        this.router.navigate(['/event/eventReady']);
      }
    });
  }

  refreshStatus() {
    if (this.eventLadder?.event) {
      this.eventService.getEventStatus(this.eventLadder.event.id!)
        .subscribe(status => this.eventLadder!.event!.status = status.code);
    }
  }

  startEvent() {
    if (this.eventLadder?.event) {
      this.eventService.updateStatus(this.eventLadder.event.id!, 'STARTED')
        .subscribe(status => this.eventLadder!.event!.status = status.code);
    }
  }

  endEvent() {
    if (this.eventLadder?.event) {
      this.eventService.updateStatus(this.eventLadder.event.id!, 'FINISHED')
        .subscribe(status => this.eventLadder!.event!.status = status.code);
    }
  }

  addTeam(team: Team) {
    if (team.id && this.eventLadder?.id) {
      this.eventService.addTeamToEventLadder(team.id, this.eventLadder.id).subscribe();
    }
  }
}
