<div class="flex justify-content-center event-content flex-1">
  <div class="center-panel large form-panel ">
    <div><span class="block-title">Liste de vos événements</span></div>
    <div class="separtor-line"></div>
    <div class="flex justify-content-end gap-3">
      <button class="p-button-success" pButton type="button" (click)="addEvent()">Ajouter</button>
    </div>
    <div class="event-list mt-2">
      <p-dataView [value]="events">
        <ng-template #dv pTemplate="list" let-events>
          <div class="flex flex-row align-items-start gap-4 py-3 flex-1" *ngFor="let event of events; let first = first" [ngClass]="{ 'border-top-1 surface-border': !first }">
            <img src="{{ event.imageData?.data ?? defaultImageUri }}" class="event-image" />
            <div class="flex flex-column align-items-start flex-1 gap-1">
              <div class="flex flex-row gap-4 align-items-center">
                <div class="event-title">{{ event.name }}</div>
                <div>
                  <p-tag [value]="event.status === 'CREATED' ? 'Nouveau' : event.status === 'CONFIG' ? 'Configuration' : event.status === 'READY' ? 'Prêt' : event.status === 'STARTED' ? 'En cours' : 'Terminé'"
                         [severity]="(event.status === 'CREATED' || event.status === 'CONFIG' || event.status === 'READY') ? 'success' : event.status === 'STARTED' ? 'warning' : 'danger'"></p-tag>
                </div>
              </div>
              <div class="block-text-justify"><p>{{ event.description }}</p></div>
            </div>
            <div class="flex flex-column align-items-center gap-3 h-full">
              <div class="event-text bold">{{ event.code }}</div>
              <div class="event-text">{{ event.eventDate | date : 'dd/MM/yyyy' }}</div>
              <div class="flex-grow-1"></div>
              <div class="flex flex-row gap-2 event-action" *ngIf="event.status === 'CREATED' || event.status === 'READY' || event.status === 'STARTED'">
                <em class="pi pi-times not-ready" *ngIf="event.status === 'READY'" pTooltip="Désactiver l'événement" tooltipPosition="bottom" [showDelay]="500" (click)="notReady(event)"></em>
                <em class="pi pi-check ready" *ngIf="event.status === 'CREATED'" pTooltip="Activer l'événement" tooltipPosition="bottom" [showDelay]="500" (click)="ready(event)"></em>
                <em class="pi pi-file-edit edit" *ngIf="event.status === 'CREATED'" pTooltip="Editer l'événement" tooltipPosition="bottom" [showDelay]="500" (click)="edit(event)"></em>
                <em class="pi pi-trash delete" *ngIf="event.status === 'CREATED' && false" pTooltip="Supprimer l'événement" tooltipPosition="bottom" [showDelay]="500"></em>
                <em class="pi pi-stop-circle" *ngIf="event.status === 'STARTED'" pTooltip="Terminer l'événement" tooltipPosition="bottom" [showDelay]="500" (click)="endEvent(event)"></em>
                <em class="pi pi-eye" *ngIf="event.status !== 'FINISHED'" pTooltip="Détails de l'événement" tooltipPosition="bottom" [showDelay]="500" (click)="showDetail(event)"></em>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template let-product pTemplate="empty"><div class="event-text">Vous n'avez aucun événements de créé</div></ng-template>
      </p-dataView>
    </div>
  </div>
</div>
