<div class="cgu-detail flex justify-content-center flex-1 h-full">
  <div class="cgu-detail-panel center-panel large h-full">
    <h3>1. Tarification</h3>
    <p>La tarification est suceptible d'évoluer dans le temps. Vous serez toujours facturé au tarif en vigueur lors de la valisation de votre commande.</p>
    <h3>2. Facturation</h3>
    <p>L'acceptation des présente conditions générales d'utilisation, ainsi que la validation de votre commande vous engage a régler le montant de votre commande dans un délai de 7 jours (sept jours).</p>
    <p>Tout retard de paiement entrainera une majoration du tarif de la commande de 10% (dix pour cent). Si après relance le montant due n'est toujours pas réglé, vous vous exposez à des poursuites judiciaires.</p>
    <h3>3. Utilisation</h3>
    <p>Une commande vous octroie un nombre de crédits a utiliser pour la création de support d'événements selon un nombre de participants. Ces crédits sont valable pendant 7 jours (sept jours) à compté de la validation de la commande.</p>
    <p>Après le lancement de votre événement (passage de celui-ci au statut En cours) vous avez 72 heures (soixante douze heures) pour arrêter ce dernier (passage de celui-ci au statut termié), sauf dérogation. Passé ce délai vous serez refacturé du montant de lévénement selon le tarif en vigueur à ce moment là pour le nombre de participant correspondant.</p>
    <h3>4. Informations générales</h3>
    <p>EventDoo est une auto-entreprise créée par Max Kaplan, pour toutes informations complémentaire ou réclamation merci de contacter Mr Kaplan au 555-3064.</p>
  </div>
</div>
