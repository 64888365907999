<div class="event-actif flex justify-content-center flex-1">
  <div class="center-panel large form-panel">
    <div><span class="block-title">Liste des événements prêts à démarrer</span></div>
    <div class="separtor-line"></div>
    <div class="flex flex-row gap-2">
      <input id="name" [(ngModel)]="code" type="text" placeholder="Code d'événement" pInputText/>
      <button class="p-button-success" pButton type="button" (click)="rejoindre()" [disabled]="!code">Rejoindre</button>
    </div>
    <div class="event-list mt-3">
      <p-dataView [value]="events">
        <ng-template let-events pTemplate="list">
          <div class="flex flex-row align-items-start gap-4 py-3 flex-1" *ngFor="let event of events; let first = first" [ngClass]="{ 'border-top-1 surface-border': !first }">
            <img src="{{ event.imageData?.data ?? defaultImageUri }}" class="event-image" />
            <div class="flex flex-column align-items-start flex-1 gap-1">
              <div class="flex flex-row gap-4 align-items-center">
                <div class="event-title">{{ event.name }}</div>
                <div>
                  <p-tag [value]="event.status === 'CREATED' ? 'Nouveau' : event.status === 'CONFIG' ? 'Configuration' : event.status === 'READY' ? 'Prêt' : event.status === 'STARTED' ? 'En cours' : 'Terminé'"
                         [severity]="(event.status === 'CREATED' || event.status === 'CONFIG' || event.status === 'READY') ? 'success' : event.status === 'STARTED' ? 'warning' : 'danger'"></p-tag>
                </div>
              </div>
              <div class="block-text-justify"><p>{{ event.description }}</p></div>
            </div>
            <div class="flex flex-column align-items-center gap-3 h-full">
              <div class="event-text bold">{{ event.code }}</div>
              <div class="event-text">{{ event.eventDate | date : 'dd/MM/yyyy' }}</div>
              <div class="flex-grow-1"></div>
              <div class="flex flex-row gap-2 event-action">
                <p-button styleClass="p-button-sm p-button-success" type="button" (click)="enter(event)">Rejoindre</p-button>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template let-product pTemplate="empty"><div class="event-text">Il n'y a aucun événement prêts que vous pouvez rejoindre</div></ng-template>
      </p-dataView>
    </div>
  </div>
</div>
