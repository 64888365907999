import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EventPiste, EventPisteScore } from 'src/app/models/event.model';
import { EventService } from 'src/app/service/event.service';

@Component({
  selector: 'app-jeu-piste-score',
  templateUrl: './jeu-piste-score.component.html',
  styleUrls: ['./jeu-piste-score.component.scss']
})
export class JeuPisteScoreComponent implements OnChanges {
  @Input() eventPiste?: EventPiste;
  @Input() canValid: boolean = true;

  eventPisteScores: EventPisteScore[] = [];
  nbStep: number = 0;
  columns: string[] = [];

  constructor(private eventService: EventService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventPiste']) {
      this.nbStep = this.eventPiste?.steps?.length ?? 0;
      this.columns = [];
      for (let index = 1; index <= this.nbStep; index++) {
        this.columns.push('Etape ' + index);
      }
    }
  }

  refreshScores() {
    if (this.eventPiste?.id) {
      this.eventService.getEventPisteScore(this.eventPiste.id).subscribe(result => this.eventPisteScores = [...result]);
    }
  }

  valider(score: EventPisteScore) {
    if (this.eventPiste?.id && score.teamId) {
      this.eventService.validateEventPisteScore(this.eventPiste.id, score.teamId).subscribe(result => this.eventPisteScores = [...result]);
    }
  }
}
