import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { switchMap, takeUntil } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { CompteService } from 'src/app/service/compte.service';
import { UserService } from 'src/app/service/user.service';
import { BaseComponent } from '../../shared/base.component';
import { Compte } from 'src/app/models/compte.model';
import { Commande, CommandeLines, Tarif } from 'src/app/models/commande.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TarifDialogComponent } from '../../tarif/tarif-dialog/tarif-dialog.component';
import { CommandeService } from 'src/app/service/commande.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { CguDetailComponent } from '../../shared/cgu-detail/cgu-detail.component';

@Component({
  selector: 'app-create-commande',
  templateUrl: './create-commande.component.html',
  styleUrls: ['./create-commande.component.scss']
})
export class CreateCommandeComponent extends BaseComponent implements OnInit {

  commandeForm = this.fb.group({
    compte: new FormControl<Compte | undefined>(undefined, Validators.required),
  });

  currentUser?: User;
  comptes: Compte[] = [];
  commandeLines: CommandeLines[] = [];
  prixTotal: number = 0;
  checkedCGU: boolean = false;

  ref: DynamicDialogRef | undefined;

  constructor(private fb: FormBuilder, private userService: UserService, private compteService: CompteService, private router: Router,
              public dialogService: DialogService, private commandeService: CommandeService, private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    this.userService.getUserConnected().pipe(
      switchMap(user => {
        if (user?.id) {
          this.currentUser = user;
          return this.compteService.findAllByUserId(user.id);
        }
        return [];
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(comptes => {
      this.comptes = comptes;
      this.commandeForm.get('compte')?.setValue(this.comptes.find(c => c.type === 'PARTICULIER'));
    });
  }

  addLigneCommande() {
    this.ref = this.dialogService.open(TarifDialogComponent, {
      header: 'Ajoutez un ou plusieurs tarifs',
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000
    });

    this.ref.onClose.subscribe((tarifs: Tarif[]) => {
      if (tarifs && tarifs.length > 0) {
        tarifs.forEach(t => {
          const commLine = new CommandeLines();
          commLine.tarif = t;
          this.commandeLines.push(commLine);
        });
        if (this.commandeLines.length > 0) {
          this.prixTotal = this.commandeLines.map(cl => cl.tarif?.prix!).reduce((acc, currentVal) => {return acc! + currentVal!}, 0);
        }
      }
    });
  }

  onSubmit() {
    if (this.commandeForm.valid && this.commandeLines.length > 0) {
      const commandeToSave = new Commande();
      commandeToSave.idCompte = this.commandeForm.get('compte')?.value?.id;
      commandeToSave.commandeLines = this.commandeLines;
      commandeToSave.prixTotal = this.prixTotal;
      this.commandeService.save(commandeToSave).subscribe(commandeSaved => {
        this.messageService.add({ severity: 'success', summary: 'Validée', detail: 'Votre commande a bien été validée, vous serez contacté prochainement pour le règlement.' });
        this.router.navigate(['/commande']);
      });
    }
  }

  displayCGU() {
    this.dialogService.open(CguDetailComponent, {header: "Conditions générales d'utilisation"});
  }
}
