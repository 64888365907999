<div class="score-boeard flex justify-content-center flex-1">
  <div class="center-panel very-large form-panel ">
    <div class="flex flex-row align-items-center gap-3">
      <span class="block-title flex-grow-1">Tableau de suivi des événements</span>
    </div>
    <div class="separtor-line"></div>
    <div class="flex flex-column gap-5 mt-5">
      <div class="flex flex-row">
        <div class="flex flex-column gap-2">
          <label class="required" htmlFor="type">Evénement</label>
          <p-dropdown class="event-list" [(ngModel)]="event" [options]="events" (onChange)="onEventChange()">
            <ng-template let-event pTemplate="selectedItem">{{ event?.name }}</ng-template>
            <ng-template let-event pTemplate="item">{{ event?.name }}</ng-template>
          </p-dropdown>
        </div>
      </div>
      <app-jeu-piste-score *ngIf="eventPiste" [eventPiste]="eventPiste" [canValid]="false"></app-jeu-piste-score>
      <app-ladder-checkpoint *ngIf="eventLadder" [eventLadder]="eventLadder" [canValid]="false"></app-ladder-checkpoint>
    </div>
  </div>
</div>
