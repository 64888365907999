<div class="play-jeu-piste flex justify-content-center flex-1">
  <div class="center-panel very-large form-panel ">
    <div class="flex flex-row align-items-center gap-3">
      <span class="block-title flex-grow-1">Evénements jeu de piste photo : {{ eventPiste?.event?.name }}</span>
      <p-tag [value]="eventPiste?.event?.status === 'STARTED' ? 'En cours' : eventPiste?.event?.status === 'FINISHED' ? 'Terminé' : 'En attente'"
                         [severity]="eventPiste?.event?.status === 'STARTED' ? 'success' : eventPiste?.event?.status === 'FINISHED' ? 'danger' : 'warning'"></p-tag>
      <em *ngIf="!isManager" class="pi pi-refresh icon-action" (click)="refreshStatus()"
          pTooltip="Mettre à jour l'événement" tooltipPosition="bottom" [showDelay]="500"></em>
      <em *ngIf="isManager && eventPiste?.event?.status === 'READY'" class="pi pi-play icon-action" (click)="startEvent()"
          pTooltip="Démarrer l'événement" tooltipPosition="bottom" [showDelay]="500"></em>
      <em *ngIf="isManager && eventPiste?.event?.status === 'STARTED'" class="pi pi-stop-circle icon-action" (click)="endEvent()"
          pTooltip="Terminer l'événement" tooltipPosition="bottom" [showDelay]="500"></em>
    </div>
    <div class="separtor-line"></div>
    <div class="flex flex-column gap-5 mt-5">
      <div class="flex flex-row info-panel align-items-center gap-2" *ngIf="team">
        <em class="pi pi-info-circle info-text"></em><span class="info-text">Vous participez à cet événement en <span *ngIf="team.solo">solo.</span><span *ngIf="!team.solo">tant qu'équipe <strong>{{ team.name }}</strong>.</span></span>
      </div>
      <app-inscription-piste *ngIf="(eventPiste?.event?.status === 'READY' || eventPiste?.event?.status === 'STARTED') && !team && !onlyManager"
          [eventPiste]="eventPiste"
          [currentUser]="currentUser"
          [isManager]="isManager"
          (onCreateTeam)="createTeam($event)"
          (onJoinTeam)="jointTeam($event)"
          (onManager)="managerOnly($event)">
      </app-inscription-piste>
      <app-jeu-piste-score *ngIf="isManager && !team && eventPiste?.event?.status !== 'READY'" [eventPiste]="eventPiste"></app-jeu-piste-score>
      <div class="grid">
        <div class="col-4" *ngFor="let step of eventPiste?.steps">
          <app-photo-card [step]="step" [team]="team" [placeholder]="placeholder" [started]="eventPiste?.event?.status === 'STARTED'"></app-photo-card>
        </div>
      </div>
    </div>
  </div>
</div>
