<p-sidebar class="side-menu" [(visible)]="sidebarMenu" position="right" (onHide)="hideMenu()">
  <ng-template pTemplate="header">
    <div *ngIf="!hasAvatar" class="no-avatar">{{firstNameLetter}}</div><img *ngIf="hasAvatar" class="avatar-img" src="{{ avatarUrl }}" /><span class="label-menu">{{ username }}</span>
  </ng-template>
  <ng-template pTemplate="content">
    <ul class="menu-block">
      <li><div (click)="goHome()"><em class="pi pi-home"></em><span>Page principale</span></div></li>
      <li><div class="separtor-line"></div></li>
      <li><div (click)="openProfile()"><em class="pi pi-user"></em><span>Profile</span></div></li>
      <li *ngIf="userValid && userRights.admin"><div class="separtor-line"></div></li>
      <li *ngIf="userValid && userRights.admin"><div (click)="openUsers()"><em class="pi pi-users"></em><span>Liste des utilisateurs</span></div></li>
      <li *ngIf="userValid && userRights.admin"><div (click)="openTarif()"><em class="pi pi-money-bill"></em><span>Les tarifs</span></div></li>
      <li *ngIf="userValid && userRights.admin"><div (click)="openFacture()"><em class="pi pi-dollar"></em><span>Les factures</span></div></li>
      <li *ngIf="userValid && userRights.admin"><div (click)="eventUsers()"><em class="pi pi-list"></em><span>Evénements utilisateurs</span></div></li>
      <li *ngIf="userValid"><div class="separtor-line"></div></li>
      <li *ngIf="userValid"><div (click)="openCompte()"><em class="pi pi-credit-card"></em><span>Comptes d'achat</span></div></li>
      <li *ngIf="userValid"><div (click)="openCommande()"><em class="pi pi-cart-plus"></em><span>Vos commandes</span></div></li>
      <li *ngIf="userValid"><div class="separtor-line"></div></li>
      <li *ngIf="userValid"><div (click)="eventList()"><em class="pi pi-list"></em><span>Vos événements</span></div></li>
      <li *ngIf="userValid"><div (click)="presetList()"><em class="pi pi-th-large"></em><span>Présets d'événements</span></div></li>
      <li *ngIf="userValid"><div (click)="createEvent()"><em class="pi pi-calendar-plus"></em><span>Créer un événements</span></div></li>
      <li *ngIf="userValid"><div (click)="eventReady()"><em class="pi pi-check-square"></em><span>Evénements prêts</span></div></li>
      <li><div class="separtor-line"></div></li>
      <li><div (click)="detailCgu()"><em class="pi pi-book"></em><span>Voir les CGU</span></div></li>
    </ul>
  </ng-template>
</p-sidebar>
