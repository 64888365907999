import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { ImageData, dialogMode } from 'src/app/models/data.model';
import { EventLadder, EventLadderStep } from 'src/app/models/event.model';
import { EventService } from 'src/app/service/event.service';

@Component({
  selector: 'app-edit-ladder-step',
  templateUrl: './edit-ladder-step.component.html',
  styleUrls: ['./edit-ladder-step.component.scss']
})
export class EditLadderStepComponent implements OnInit {

  @ViewChild('imageUpload') public imageUpload?: FileUpload;

  stepForm = this.fb.group({
    name: ['', Validators.required],
    position: [''],
    imageName: [''],
  });

  mode: dialogMode = 'CREATE';
  imageName?: string;
  imageType?: string;
  imageDataUrl?: string;
  uploadedFile?: File;
  eventLadder?: EventLadder;
  eventLadderStep?: EventLadderStep;
  type?: string;

  constructor(private fb: FormBuilder, public ref: DynamicDialogRef, public config: DynamicDialogConfig, private eventService: EventService) {}

  ngOnInit(): void {
    if (this.config.data) {
      this.mode = this.config.data.mode;
      this.type = this.config.data.type;
    }
    if (this.config.data.eventLadder) {
      this.eventLadder = this.config.data.eventLadder;
    }
    if (this.config.data.stepId) {
      this.eventService.findLadderStep(this.config.data.stepId).subscribe(result => {
        this.eventLadderStep = result;
          this.stepForm.patchValue({
            name: this.eventLadderStep.name,
            position: this.eventLadderStep.position,
            imageName: this.eventLadderStep.imageData?.imageName,
          });
          this.imageDataUrl = this.eventLadderStep.imageData?.data;
          this.imageName = this.eventLadderStep.imageData?.imageName;
          this.imageType = this.eventLadderStep.imageData?.imageType;
      });
    }
  }

  onSubmit() {
    if (this.stepForm.valid) {
      let stepToSave: EventLadderStep;
      if (this.mode === 'CREATE') {
        stepToSave = new EventLadderStep();
        stepToSave.ordre = (this.eventLadder?.steps?.length ?? 0) + 1;
        stepToSave.eventLadderId = this.eventLadder?.id;
      } else {
        stepToSave = {...this.eventLadderStep};
      }
      if (!stepToSave.imageData) {
        stepToSave.imageData = new ImageData();
      }
      stepToSave.imageData.data = this.imageDataUrl;
      stepToSave.imageData.imageName = this.imageName;
      stepToSave.imageData.imageType = this.imageType;
      stepToSave.name = this.stepForm.get('name')?.value ?? '';
      stepToSave.position = this.stepForm.get('position')?.value ?? undefined;
      stepToSave.type = this.type;
      this.eventService.saveLadderStep(stepToSave).subscribe(stepSaved => {
        this.stepForm.reset();
        this.imageDataUrl = undefined;
        this.imageName = undefined;
        this.imageType = undefined;
        this.ref.close(stepSaved);
      });
    }
  }

  uploadFileHandler(event: any) {
    if (this.imageUpload && event && event.files && event.files.length > 0) {
      this.imageName = event.files[0].name;
      this.imageType = event.files[0].type;
      this.uploadedFile = event.files[0];
      this.imageUpload.clear();
      const reader = new FileReader();
      reader.readAsDataURL(event.files[0]);
      reader.onload = (() => {
        this.imageDataUrl =  reader.result?.toString();
        this.stepForm.get('imageName')?.setValue(this.imageName!);
      });
    }
  }
}
