import { Commande } from './../../models/commande.model';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/service/user.service';
import { BaseComponent } from '../shared/base.component';
import { switchMap, takeUntil } from 'rxjs';
import { CommandeService } from 'src/app/service/commande.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-commande',
  templateUrl: './commande.component.html',
  styleUrls: ['./commande.component.scss']
})
export class CommandeComponent extends BaseComponent implements OnInit {
  currentUser?: User;
  commandes: Commande[] = [];

  constructor(private userService: UserService, private commandeService: CommandeService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.userService.getUserConnected().pipe(
      switchMap(user => {
        if (user?.id) {
          this.currentUser = user;
          return this.commandeService.findAllByUserId(user.id);
        }
        return [];
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(commandes => this.commandes = commandes);
  }

  addCommande() {
    this.router.navigate(['/commande/create']);
  }

  consulter(commande: Commande) {

  }
}
