import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import { BaseComponent } from '../shared/base.component';
import { combineLatest, of, switchMap, takeUntil, zip } from 'rxjs';
import { User, UserRights } from 'src/app/models/user.model';
import { LabelUtils } from 'src/app/shared/utils/label.utils';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseComponent implements OnInit {
  profileForm = this.fb.group({
    nom: ['', Validators.required],
    prenom: ['', Validators.required],
    telephone: ['', Validators.required],
  });

  user?: User;
  username?: string;
  avatarBaseUrl: string = 'https://cdn.discordapp.com/avatars/';
  avatarUrl: string = '';
  userRights: UserRights = {guest: true, user: false, admin: false};
  hasAvatar: boolean = true;
  firstNameLetter?: string;

  constructor(private userService: UserService, private authService: AuthService, private router: Router, private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.userService.getUserConnected().pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      if (user) {
        this.user = user;
        this.username = LabelUtils.toCamelCase(user.name ?? '');
        this.avatarUrl = this.avatarBaseUrl + user?.discordId + '/' + user?.avatar + '?size=96';
        this.profileForm.patchValue({
          nom: user.nom,
          prenom: user.prenom,
          telephone: user.telephone
        });
        if (!user.avatar) {
          this.hasAvatar = false
          this.firstNameLetter = user.name?.substring(0, 1).toLocaleUpperCase();
        }
      }
    });
    this.userService.getUserRights().pipe(takeUntil(this.ngUnsubscribe)).subscribe(rights => this.userRights = rights);
  }

  onSubmit() {
    if (this.profileForm.valid) {
      const userToSave = this.formToUser();
      if (userToSave) {
        this.userService.save(userToSave).pipe(
          switchMap(userSaved => {
            if (!this.user?.validate && userSaved.validate) {
              return combineLatest([of(userSaved), this.authService.refresh()]);
            }
            return zip(of(userSaved), of(''));
          })
        ).subscribe(([userSaved, token]) => {
          this.userService.updateUserConnected(userSaved);
          this.router.navigate(['/home']);
        });
      }
    }
  }

  private formToUser(): User | undefined {
    if (this.user) {
      this.user.nom = this.profileForm.get('nom')?.value ?? '';
      this.user.prenom = this.profileForm.get('prenom')?.value ?? '';
      this.user.telephone = this.profileForm.get('telephone')?.value ?? '';
    }
    return this.user;
  }
}
