import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class CreditService {
  constructor(private http: HttpClient) {}

  countCredit(compteId: number, nbParticipant: number): Observable<number> {
    return this.http.get<number>('/credit/countCredit/' + compteId + '/' + nbParticipant);
  }
}
