import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { User } from 'src/app/models/user.model';
import { SuiviRightService } from 'src/app/service/suivi-right.service';

@Component({
  selector: 'app-add-suivi-right',
  templateUrl: './add-suivi-right.component.html',
  styleUrl: './add-suivi-right.component.scss'
})
export class AddSuiviRightComponent implements OnInit {
  users: User[] = [];

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private suiviRightService: SuiviRightService) {}

  ngOnInit(): void {
    if (this.config.data) {
      this.suiviRightService.findUserByEvent(this.config.data.eventId).subscribe(users => this.users = users);
    }
  }

  validate() {
    this.suiviRightService.addUser(this.users, this.config.data.eventId).subscribe(() => this.close());
  }

  close() {
    this.ref.close(undefined);
  }
}
