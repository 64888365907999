import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Event } from 'src/app/models/event.model';
import { User, UserRights } from 'src/app/models/user.model';
import { EventService } from 'src/app/service/event.service';
import { UserService } from 'src/app/service/user.service';
import { BaseComponent } from '../../shared/base.component';
import { combineLatest, of, switchMap, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageUtils } from 'src/app/shared/utils/Image.utils';
import { Compte } from 'src/app/models/compte.model';
import { CompteService } from 'src/app/service/compte.service';
import { CreditService } from 'src/app/service/credit.service';
import { TarifService } from 'src/app/service/tarif.service';
import { DialogService } from 'primeng/dynamicdialog';
import { EventConfigDetailComponent } from '../event-config-detail/event-config-detail.component';
import { CreateEventDialogComponent } from '../create-event-dialog/create-event-dialog.component';
import { ImageData } from 'src/app/models/data.model';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent extends BaseComponent implements OnInit {
  @ViewChild('imageUpload') public imageUpload?: FileUpload;

  messages: Message[] = [];

  eventForm = this.fb.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    eventDate: new FormControl<Date | undefined>(undefined, Validators.required),
    type: new FormControl<{label: string, code: string}>({label: '', code: ''}, Validators.required),
    managers: [''],
    guests: [''],
    publicEvent: new FormControl<boolean>(false),
    compte: new FormControl<Compte | undefined>(undefined, Validators.required),
    nbParticipant: new FormControl<SelectItem<number> | undefined>(undefined, Validators.required),
  });
  defaultImageUri?: string;
  mode: 'CREATE' | 'EDIT' = 'CREATE';
  uploadedFile?: File;
  imageName?: string;
  imageType?: string;
  imageDataUrl?: string;
  event?: Event;
  currentUser?: User;
  managers: User[] = [];
  guests: User[] = [];
  userRights: UserRights = {guest: true, user: false, admin: false};
  preset: boolean = false;

  types: {label: string, code: string}[] = [{label: 'Jeu de piste', code: 'JEU_PISTE_IMAGE'}, {label: 'Classement', code: 'LADDER'}];
  comptes: Compte[] = [];
  participants: SelectItem<number>[] = [];
  creditCount: number = 0;

  constructor(private fb: FormBuilder, private eventService: EventService,
              private userService: UserService, private messageService: MessageService,
              private router: Router, private route: ActivatedRoute, private compteService: CompteService,
              private creditService: CreditService, private tarifService: TarifService, public dialogService: DialogService) {
    super();
  }

  ngOnInit(): void {
    this.userService.getUserRights().pipe(takeUntil(this.ngUnsubscribe)).subscribe(rights => this.userRights = rights);
    this.defaultImageUri = ImageUtils.getDefaultEvent();
    this.eventForm.get('type')?.setValue(this.types[0]);
    this.tarifService.getListNbPersonne().subscribe(results => {
      results.sort((n1, n2) => n2 === 0 ? -1 : n1 - n2);
      results.forEach(num => {
        this.participants.push({label: num === 0 ? 'Participants illimité' : num + ' participants', value: num});
      });
      if (this.participants.length > 0) {
        this.eventForm.get('nbParticipant')?.setValue(this.participants[0]);
      }
    });
    this.userService.getUserConnected().pipe(
      switchMap(user => {
        return combineLatest([of(user), this.compteService.findAllByUserId(user?.id!)]);
      }),
      switchMap(([user, comptes]) => {
        this.comptes = comptes;
        if (this.comptes.length > 0) {
          this.eventForm.get('compte')?.setValue(this.comptes.find(c => c.type === 'PARTICULIER'));
        }
        this.currentUser = user;
        return this.route.params;
      }),
      switchMap(params => {
        if (params['id'] && this.currentUser?.id) {
          this.mode = 'EDIT';
          return this.eventService.findByIdAndOwnerId(Number(params['id']), this.currentUser.id);
        }
        return of(undefined);
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(event => {
      if (event) {
        this.event = event;
        this.updateForm(event);
      } else if (this.mode === 'EDIT') {
        this.messageService.add({ severity: 'error', summary: 'Evénement', detail: 'l\'événement n\'existe pas ou vous n\'avez pas les droits dessus.' });
      }
      this.updateCredit();
    });
  }

  uploadFileHandler(event: any) {
    if (this.imageUpload && event && event.files && event.files.length > 0) {
        this.imageName = event.files[0].name;
        this.imageType = event.files[0].type;
        this.uploadedFile = event.files[0];
        this.imageUpload.clear();
        const reader = new FileReader();
        reader.readAsDataURL(event.files[0]);
        reader.onload = (() => this.imageDataUrl =  reader.result?.toString());
    }
  }

  onSubmit() {
    if (!this.event?.id && this.creditCount === 0) {
      const dialogRef = this.dialogService.open(CreateEventDialogComponent, {
        header: "Validation de la commande de l'événement",
        width: '40vw',
        data: {
          nbParticipant: this.eventForm.get('nbParticipant')?.value?.value ?? 0,
          typeEvent: this.eventForm.get('type')?.value?.label,
        }
      });
      dialogRef.onClose.subscribe(validate => {
        if (validate) {
          this.save();
        }
      });
    } else {
      this.save();
    }
  }

  onConfigure() {
    if (this.event?.id) {
      switch(this.event.type) {
        case 'JEU_PISTE_IMAGE': {
          this.router.navigate(['/event/jeupisteConfig', this.event.id]);
          break;
        }
        case 'LADDER': {
          this.router.navigate(['/event/ladderConfig', this.event.id]);
          break;
        }
      }
    }
  }

  onCompteChange() {
    this.updateCredit();
  }

  onParticipantChange() {
    this.updateCredit();
  }

  addMessge() {
    if (!this.event?.id && this.creditCount === 0) {
      if (this.messages.length === 0) {
        this.messages = [{ severity: 'warn', detail: "Vous n'avez pas assez de crédits, une facture sera automatiquement éditée à la création de l'événement."}];
      }
    } else {
      this.clearMessage();
    }
  }

  clearMessage() {
    this.messages = [];
  }

  showDetail() {
    this.dialogService.open(EventConfigDetailComponent, {
      header: "Détail de la configuration de l'événement",
      width: '50vw',
      data: {
        eventId: this.event?.id,
      }
    });
  }

  private save() {
    if (this.checkValidity()) {
      this.eventService.save(this.formToEvent()).subscribe(eventSaved => {
        this.event = eventSaved;
        this.messageService.add({ severity: 'success', summary: 'Enregister', detail: 'l\'événement à bien été enregister' });
        this.updateCredit();
        this.eventForm.get('compte')?.disable();
        this.eventForm.get('nbParticipant')?.disable();
        this.eventForm.get('type')?.disable();
        this.mode = 'EDIT';
      });
    }
  }

  private checkValidity(): boolean {
    let validity: boolean = true;
    if (!this.eventForm.valid) {
      validity = false;
    }
    const nbParticipant = this.eventForm.get('nbParticipant')?.value;
    if (nbParticipant && nbParticipant.value > 0 && !((nbParticipant.value / 5) >= this.managers.length)) {
      validity = false;
      this.messageService.add({ severity: 'error', summary: 'Manager', detail: 'Le nombre de managers ne peut pas excéder 1/5ème du nombre de participants.' });
    }
    return validity;
  }

  private updateForm(event: Event) {
    this.eventForm.patchValue({
      name: event.name,
      description: event.description,
      eventDate: event.eventDate ? DateTime.fromISO(event.eventDate).toJSDate() : undefined,
      publicEvent: event.publicEvent ?? false,
      nbParticipant: this.participants.find(p => p.value === event.nbParticipant),
      compte: this.comptes.find(c => c.id === event.compteId),
    });
    const type: {label: string, code: string} | undefined = this.types.find(t => t.code === event.type);
    this.eventForm.get('type')?.setValue(type ?? null);
    this.managers = event.managers ?? [];
    this.guests = event.guests ?? [];
    if (event.imageData?.data) {
      this.imageDataUrl = event.imageData.data;
      this.imageName = event.imageData.imageName;
      this.imageType = event.imageData.imageType;
    }
    this.preset = event.preset ?? false;
    this.eventForm.get('compte')?.disable();
    this.eventForm.get('nbParticipant')?.disable();
    this.eventForm.get('type')?.disable();
  }

  private formToEvent(): Event {
    const eventToSave: Event = new Event();

    if (this.event) {
      eventToSave.id = this.event.id;
      eventToSave.status = this.event.status;
      eventToSave.code = this.event.code;
    }
    eventToSave.name = this.eventForm.get('name')?.value ?? '';
    eventToSave.description = this.eventForm.get('description')?.value ?? '';
    eventToSave.type = this.eventForm.get('type')?.value?.code;
    eventToSave.publicEvent = this.eventForm.get('publicEvent')?.value ?? false;
    const date = this.eventForm.get('eventDate')?.value;
    if (date) {
      eventToSave.eventDate = DateTime.fromJSDate(date).toISO() ?? '';
    }
    if (this.imageDataUrl) {
      eventToSave.imageData = new ImageData();
      eventToSave.imageData.data = this.imageDataUrl;
      eventToSave.imageData.imageName = this.imageName;
      eventToSave.imageData.imageType = this.imageType;
    }
    if (this.currentUser) {
      eventToSave.ownerId = this.currentUser.id;
    }
    eventToSave.managers = this.managers;
    eventToSave.guests = this.guests;
    eventToSave.compteId = this.eventForm.get('compte')?.value?.id;
    const nbParticipant = this.eventForm.get('nbParticipant')?.value;
    eventToSave.nbParticipant = nbParticipant ? nbParticipant.value : 0;
    eventToSave.preset = this.preset;

    return eventToSave;
  }

  private updateCredit() {
    if (this.eventForm.get('compte')?.value && this.eventForm.get('nbParticipant')?.value) {
      this.creditService.countCredit(this.eventForm.get('compte')?.value?.id!, this.eventForm.get('nbParticipant')?.value?.value!)
        .subscribe(count => {
          this.creditCount = count ?? 0;
          this.addMessge();
        });
    }
  }
}
