<div class="profile-content flex justify-content-center">
  <div class="profile-panel center-panel form-panel">
    <div><span class="block-title">Gestion du profile</span></div>
    <div class="separtor-line"></div>
    <div class="flex flex-column">
      <div *ngIf="!hasAvatar" class="no-avatar">{{firstNameLetter}}</div><img *ngIf="hasAvatar" src="{{ avatarUrl }}" width="96px" />
      <span class="user-name">{{ username }}<span class="user-name-admin" *ngIf="userRights.admin"> (Admin)</span></span>
    </div>
    <div class="separtor-line"></div>
    <div><p><em class="pi pi-info-circle"></em> Merci de renseigner vos nom, prénom et téléphone 21 afin de pouvoir vous rattacher à votre personnage.</p></div>
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-column gap-3">
        <div class="flex flex-column gap-2">
          <label class="required" htmlFor="nom">Nom</label>
          <input id="nom" type="text" pInputText formControlName="nom"/>
        </div>
        <div class="flex flex-column gap-2">
          <label class="required" htmlFor="prenom">Prénom</label>
          <input id="prenom" type="text" pInputText formControlName="prenom"/>
        </div>
        <div class="flex flex-column gap-2">
          <label class="required" htmlFor="tel">Téléphone</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">555 -</span>
            <input pInputText pKeyFilter="int" formControlName="telephone" inputId="tel" />
          </div>
        </div>
      </div>
      <div class="separtor-line"></div>
      <div class="flex justify-content-end mt-3"><button class="p-button-success" pButton type="submit" [disabled]="!profileForm.valid">Sauvegarder</button></div>
    </form>
  </div>
</div>
