import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { of, switchMap, takeUntil } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { EventService } from 'src/app/service/event.service';
import { BaseComponent } from '../../shared/base.component';
import { EventPiste, EventPisteSteps } from 'src/app/models/event.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EditJeuPisteComponent } from './edit-jeu-piste/edit-jeu-piste.component';
import { PresetJeuPisteComponent } from './preset-jeu-piste/preset-jeu-piste.component';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-jeu-piste-event',
  templateUrl: './jeu-piste-event.component.html',
  styleUrls: ['./jeu-piste-event.component.scss']
})
export class JeuPisteEventComponent extends BaseComponent implements OnInit {

  currentUser?: User;
  eventPiste?: EventPiste;

  ref: DynamicDialogRef | undefined;

  constructor(private eventService: EventService, private authService: AuthService,
    private messageService: MessageService, private router: Router, private route: ActivatedRoute, public dialogService: DialogService){
    super();
  }

  ngOnInit(): void {
    this.authService.getConnectedUser().pipe(
      switchMap(user => {
        this.currentUser = user;
        return this.route.params;
      }),
      switchMap(params => {
        if (params['id'] && this.currentUser?.id) {
          return this.eventService.findPisteByEventIdUserId(Number(params['id']), this.currentUser.id);
        }
        return of(undefined);
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(eventPiste => {
      if (eventPiste) {
        this.eventPiste = eventPiste;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Evénement', detail: 'l\'événement jeu de piste n\'existe pas ou vous n\'avez pas les droits dessus.' });
        this.router.navigate(['/event']);
      }
    });
  }

  addStep() {
    this.ref = this.dialogService.open(EditJeuPisteComponent, {
      header: 'Ajouter une étape',
      data: {
        mode: 'CREATE',
        eventPiste: this.eventPiste,
      }
    });

    this.ref.onClose.subscribe((eventStep: EventPisteSteps) => {
      if (eventStep) {
        this.eventPiste?.steps?.push(eventStep);
        this.messageService.add({ severity: 'success', summary: 'Créé', detail: 'l\'étape de l\'événement a été ajouté.' });
      }
    });
  }

  editStep(step: EventPisteSteps) {
    this.ref = this.dialogService.open(EditJeuPisteComponent, {
      header: "Edition de l'étape",
      data: {
        mode: 'EDIT',
        eventPiste: this.eventPiste,
        step: step
      }
    });

    this.ref.onClose.subscribe((eventStep: EventPisteSteps) => {
      if (eventStep) {
        this.eventPiste!.steps = [...this.eventPiste?.steps?.filter(s => s.id !== eventStep.id) ?? [], eventStep];
        this.eventPiste!.steps.sort((s1, s2) => s1.ordre! - s2.ordre!);
        this.messageService.add({ severity: 'success', summary: 'Modifié', detail: 'l\'étape de l\'événement a été modifié.' });
      }
    });
  }

  addPreset() {
    this.ref = this.dialogService.open(PresetJeuPisteComponent, {
      header: "Choix d'une présélection",
      height: '80vh',
      data: {
        eventPiste: this.eventPiste,
      }
    });

    this.ref.onClose.subscribe((eventSteps: EventPisteSteps[]) => {
      if (eventSteps && eventSteps.length > 0) {
        this.eventPiste!.steps = [...this.eventPiste?.steps ?? [], ...eventSteps];
        this.eventPiste!.steps.sort((s1, s2) => s1.ordre! - s2.ordre!);
        this.messageService.add({ severity: 'success', summary: 'Ajout', detail: 'la lisete des étapes présélectionées ont été ajoutées.' });
      }
    });
  }

  openEvents() {
    this.router.navigate(['/event']);
  }
}
