import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

export class HttpFilter implements HttpInterceptor {

  private apiUrl = environment.apiUrl;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(request));
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    if (request.url.includes('assets/i18n')) {
      return request;
    } else {
      if (!request.url.includes('/auth/')) {
        const token = localStorage.getItem('access.token');
        if (token) {
          request = request.clone({
            setHeaders: {Authorization: 'Bearer ' + token}
          });
        }
      }
      // request = request.clone({
      //   setHeaders: {'Access-Control-Allow-Origin': 'http://localhost', 'Access-Control-Allow-Credentials': 'true'}
      // });
      return request.clone({url: `${this.apiUrl}${request.url}`});
    }
  }

}
