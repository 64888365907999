import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Event } from 'src/app/models/event.model';
import { EventService } from 'src/app/service/event.service';
import { ImageUtils } from 'src/app/shared/utils/Image.utils';
import { CopyPresetComponent } from '../../event/copy-preset/copy-preset.component';
import { HomeService } from 'src/app/service/home.service';
import { Router } from '@angular/router';
import { TarifDialogComponent } from '../../tarif/tarif-dialog/tarif-dialog.component';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-home-right',
  templateUrl: './home-right.component.html',
  styleUrls: ['./home-right.component.scss']
})
export class HomeRightComponent implements OnInit {
  @Input() userConnected?: User;

  defaultImageUri?: string;
  events: Event[] = [];

  constructor(private homeService: HomeService, public dialogService: DialogService, private router: Router){}

  ngOnInit(): void {
    this.defaultImageUri = ImageUtils.getDefaultEvent();
    this.homeService.findLastPreset().subscribe(results => this.events = results);
  }

  duplicate(event: Event) {
    this.dialogService.open(CopyPresetComponent, {
      header: 'Copie du préset',
      width: '50%',
      data: {
        preset: event,
      }
    });
  }

  openPresets() {
    this.router.navigate(['/event/presets']);
  }

  openTarif() {
    this.dialogService.open(TarifDialogComponent, {
      header: 'Liste des tarifs',
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: { modeConsult: true }
    });
  }
}
