import { Component, OnInit } from "@angular/core";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { EventPiste } from "src/app/models/event.model";
import { Team } from "src/app/models/team.model";
import { User } from "src/app/models/user.model";
import { TeamService } from "src/app/service/team.service";

@Component({
  selector: 'app-inscription-piste-team',
  templateUrl: './inscription-piste-team.component.html',
  styleUrls: ['./inscription-piste-team.component.scss']
})
export class InscriptionPisteTeamComponent implements OnInit {
  teamName?: string;
  teamValid: boolean = false;
  mode: 'CREATE' | 'ADD' = 'CREATE';
  team?: Team;
  currentUser?: User;
  eventPiste?: EventPiste;
  teamSuggestions: Team[] = [];

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private teamService: TeamService) {
  }

  ngOnInit(): void {
    if (this.config.data) {
      this.currentUser = this.config.data.user;
      this.mode = this.config.data.mode;
      this.eventPiste = this.config.data.eventPiste;
    }
  }

  searchTeam(event: AutoCompleteCompleteEvent) {
    if (event && this.eventPiste) {
      this.teamService.findByQueryEventPisteId(event.query, this.eventPiste.id!).subscribe(results => this.teamSuggestions = [...results]);
    }
  }

  valider() {
    if (this.mode === 'CREATE') {
      this.team = {members: [], name: this.teamName, captain: this.currentUser, solo: false};
    }
    this.ref.close(this.team);
  }

  close() {
    this.ref.close();
  }

  isTeamValid() {
    this.teamValid = (this.mode === 'CREATE' && !!this.teamName && this.teamName !== '') || (this.mode === 'ADD' && !!this.team);
  }
}
