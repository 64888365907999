import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { News } from "../models/news.model";

@Injectable({providedIn: 'root'})
export class NewsService {
  constructor(private http: HttpClient) {}

  create(news: News): Observable<News> {
    return this.http.post<News>('/news/createNews', news);
  }

  update(news: News): Observable<News> {
    return this.http.post<News>('/news/updateNews', news);
  }

}
