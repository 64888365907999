import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable, OnInit } from "@angular/core";
import { Observable, of, switchMap, take, takeUntil } from 'rxjs';
import { UserService } from 'src/app/service/user.service';

@Injectable({providedIn: 'root'})
export class AdminGuard implements CanActivate  {
  constructor(private userService: UserService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.userService.getUserRights().pipe(take(1), switchMap(userRights => of(!!userRights.admin)));
  }

}
