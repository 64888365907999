<div class="flex flex-column flex-1 home-left-content gap-3">
  <div class="flex flex-column tips info-panel gap-3">
    <div class="flex justify-content-center"><span class="block-title subtitle">A savoir !</span></div>
    <div class="flex flex-column">
      <div class="flex block-text-justify"><p>Pour créer votre événement vous devez disposer de crédits qui s'obtiennent en passant une commande pour le nombre de participants désiré.</p></div>
      <div class="flex block-text-justify"><p>La liste de vos commandes vous indique celles que vous avez payé.</p></div>
      <div class="flex justify-content-center button-action"><p-button icon="pi pi-cart-plus" label="Passer commande" (onClick)="openCommande()" [disabled]="!userConnected?.validate"></p-button></div>
    </div>
    <div class="flex flex-column">
      <div class="flex block-text-justify"><p>Lors de la création d'un événement le choix du compte et du nombre de participants vous indique le nombre de crédits dont vous disposez pour ces deux critères.</p></div>
      <div class="flex justify-content-center button-action"><p-button icon="pi pi-calendar-plus" label="Créer un événement" (onClick)="openEvent()" [disabled]="!userConnected?.validate"></p-button></div>
    </div>
    <div class="flex flex-column">
      <div class="flex block-text-justify"><p>Une fois votre événement créé et configuré il ne vous reste plus qu'a l'activer dans la liste de vos événements pour que les participants le rejoigne.</p></div>
      <div class="flex justify-content-center button-action"><p-button icon="pi pi-list" label="Vos événements" (onClick)="openEventList()" [disabled]="!userConnected?.validate"></p-button></div>
    </div>
    <div class="flex flex-column">
      <div class="flex block-text-justify"><p>Dans la lsite des événements prêts vous retrouvez les événements public ou auquels vous êtes invité qui sont prêt a démarrer et que vous pouvez rejoindre.</p></div>
      <div class="flex justify-content-center button-action"><p-button icon="pi pi-check-square" label="Evénements prêts" (onClick)="openEventReady()" [disabled]="!userConnected?.validate"></p-button></div>
    </div>
  </div>
  <div class="flex"></div>
  <div class="flex">
    <div class="app-info" [pTooltip]="appInfoContent" tooltipStyleClass="app-info-tooltip"><em class="pi pi-info-circle"></em></div>
    <ng-template #appInfoContent>
      <div class="flex flex-column gap-2">
        <div class="flex align-items-center">
            <img src="../../../../assets/images/Logo_32.png" height="20" class="mr-2" />
            <span>EventDoo v1.9.2</span>
        </div>
        <div><span>Pour toutes informations merci de contacter Mr Kaplan au 555-3064.</span></div>
      </div>
  </ng-template>
  </div>
</div>
