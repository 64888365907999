export class LabelUtils {
  static toCamelCase(value: string): string {
    return value.substring(0, 1).toLocaleUpperCase() + value.substring(1, value.length);
  }

  static eventTypeLabel(value: string): string {
    switch(value) {
      case 'JEU_PISTE_IMAGE':
        return 'Jeu de piste';
      default:
        return '';
    }
  }
}
