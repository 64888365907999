import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../shared/base.component';
import { User, UserRights } from 'src/app/models/user.model';
import { UserService } from 'src/app/service/user.service';
import { switchMap, takeUntil, timeout } from 'rxjs';
import { Router } from '@angular/router';
import { LabelUtils } from 'src/app/shared/utils/label.utils';
import { DialogService } from 'primeng/dynamicdialog';
import { CguDetailComponent } from '../shared/cgu-detail/cgu-detail.component';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent extends BaseComponent implements OnInit {
  @Input() sidebarMenu: boolean = false;
  @Output() sidebarMenuChange = new EventEmitter<boolean>();

  avatarBaseUrl: string = 'https://cdn.discordapp.com/avatars/';
  avatarUrl: string = '';
  user?: User;
  username?: string;
  userValid: boolean = false;
  userRights: UserRights = {guest: true, user: false, admin: false};
  hasAvatar: boolean = true;
  firstNameLetter?: string;

  constructor(private userService: UserService, private router: Router, public dialogService: DialogService) {
    super();
  }

  ngOnInit(): void {
    this.userService.getUserConnected().pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      this.user = user;
      if (user) {
        this.username = LabelUtils.toCamelCase(user.name ?? '');
        this.avatarUrl = this.avatarBaseUrl + user?.discordId + '/' + user?.avatar + '?size=32';
        this.userValid = user.validate;
        if (!user.avatar) {
          this.hasAvatar = false
          this.firstNameLetter = user.name?.substring(0, 1).toLocaleUpperCase();
        }
      }
    });
    this.userService.getUserRights().pipe(takeUntil(this.ngUnsubscribe)).subscribe(rights => this.userRights = rights);
  }

  hideMenu() {
    this.sidebarMenuChange.emit(false);
  }

  openProfile() {
    this.hideSideBar();
    this.router.navigate(['/profile', this.user?.name])
  }

  openUsers() {
    this.hideSideBar();
    this.router.navigate(['/users'])
  }

  openCompte() {
    this.hideSideBar();
    this.router.navigate(['/compte']);
  }

  openCommande() {
    this.hideSideBar();
    this.router.navigate(['/commande']);
  }

  openTarif() {
    this.hideSideBar();
    this.router.navigate(['/tarif']);
  }

  openFacture() {
    this.hideSideBar();
    this.router.navigate(['/facture']);
  }

  goHome() {
    this.hideSideBar();
    setTimeout(() => this.router.navigate(['/home']), 100);
  }

  eventList() {
    this.hideSideBar();
    this.router.navigate(['/event']);
  }

  presetList() {
    this.hideSideBar();
    this.router.navigate(['/event/presets']);
  }

  createEvent() {
    this.hideSideBar();
    this.router.navigate(['/event/create']);
  }

  eventReady() {
    this.hideSideBar();
    this.router.navigate(['/event/eventReady']);
  }

  eventUsers() {
    this.hideSideBar();
    this.router.navigate(['/event/users']);
  }

  detailCgu() {
    this.hideSideBar();
    this.dialogService.open(CguDetailComponent, {header: "Conditions générales d'utilisation"});
  }

  private hideSideBar() {
    this.sidebarMenu = false;
    this.sidebarMenuChange.emit(false);
  }
}
