import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";

import { Observable, map } from "rxjs";
import { UserService } from "./user.service";
import { environment } from "src/environments/environment";
import { Token } from "../models/token.model";
import { User } from "../models/user.model";

@Injectable({providedIn: 'root'})
export class AuthService {
  private clientUri = environment.clientUri;

  constructor(private http: HttpClient, private router: Router, private userService: UserService, private jwtHelperService: JwtHelperService) {}

  login(token: Token): string | undefined {
    let username = undefined;
    if (token && token.accessToken && token.refreshToken) {
      localStorage.setItem('access.token', token.accessToken);
      localStorage.setItem('refresh.token', token.refreshToken);
      username = this.getLoginFromToken();
    }
    return username;
  }

  logout(): any {
    localStorage.removeItem('access.token');
    localStorage.removeItem('refresh.token');
    this.userService.clearUserConnected();
    this.router.navigate(['/login']);
  }

  retrieveUserConnectedIfToken() {
    const username = this.getLoginFromToken();
    if (username) {
      this.userService.setUserConnected(username).subscribe();
    }
  }

  getLoginFromToken(): string | undefined {
    let username = undefined;
    const token = localStorage.getItem('access.token');
    if (token) {
      const tokenDetail = this.jwtHelperService.decodeToken(token);
      username = tokenDetail['sub'];
    }
    return username;
  }

  getConnectedUser(): Observable<User | undefined> {
    return this.userService.getConnectedUser(this.getLoginFromToken());
  }

  public isAuthentified(): boolean {
    const accessToken = localStorage.getItem('refresh.token');
    if (accessToken && !this.jwtHelperService.isTokenExpired(accessToken)) {
      return true;
    }
    return false;
  }

  public refresh(): Observable<string> {
    const refreshToken = localStorage.getItem('refresh.token');
    return this.http.post<any>('/auth/refresh', {refreshToken}).pipe(
      map(token => {
        if (token && token.accessToken) {
          localStorage.setItem('access.token', token.accessToken);
        }
        return token.accessToken;
      })
    );
  }
}
