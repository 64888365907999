import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval, of, switchMap, takeUntil } from 'rxjs';
import { Event } from 'src/app/models/event.model';
import { EventService } from 'src/app/service/event.service';
import { UserService } from 'src/app/service/user.service';
import { ImageUtils } from 'src/app/shared/utils/Image.utils';
import { BaseComponent } from '../../shared/base.component';
import { User } from 'src/app/models/user.model';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-event-actif',
  templateUrl: './event-actif.component.html',
  styleUrls: ['./event-actif.component.scss']
})
export class EventActifComponent extends BaseComponent implements OnInit {
  defaultImageUri?: string;
  events: Event[] = [];
  code?: string;
  currentUser?: User;

  constructor(private eventService: EventService, private userService: UserService, private router: Router, private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    this.defaultImageUri = ImageUtils.getDefaultEvent();
    this.userService.getUserConnected().pipe(
      switchMap(user => {
        if (user?.id) {
          this.currentUser = user;
          return this.eventService.findAllReady(user.id);
        }
        return [];
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(events => this.events = events);

    interval(10000).pipe(
      switchMap(() => this.eventService.findAllReady(this.currentUser?.id!)),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(events => this.events = events);
  }

  enter(event: Event) {
    if (event && this.currentUser) {
      this.eventService.canJoinEvent(event.id!, this.currentUser?.id!).subscribe(canJoin => {
        if (canJoin) {
          this.navigateToEventById(event);
        } else {
          this.messageService.add({ severity: 'error', summary: 'Evénement', detail: "Il n'y a plus de places pour cet événement." });
        }
      });
    }
  }

  rejoindre() {
    if (this.code && this.currentUser) {
      this.eventService.canJoinEventCode(this.code, this.currentUser?.id!).pipe(
        switchMap(canJoin => {
          if (canJoin) {
            return this.eventService.findByCode(this.code!);
          } else {
            this.messageService.add({ severity: 'error', summary: 'Evénement', detail: "Il n'y a plus de places pour cet événement." });
            return of(undefined);
          }
        }),
      ).subscribe(event => {
        this.navigateToEventByCode(event);
      });
    }
  }

  private navigateToEventById(event: Event | undefined) {
    if (event) {
      if (event.type === 'JEU_PISTE_IMAGE') {
        this.router.navigate(['/event/JeuDePiste', event.id]);
      } else if (event.type === 'LADDER') {
        this.router.navigate(['/event/ladder', event.id]);
      }
    }
  }

  private navigateToEventByCode(event: Event | undefined) {
    if (event) {
      if (event.type === 'JEU_PISTE_IMAGE') {
        this.router.navigate(['/event/JeuDePisteByCode', this.code]);
      } else if (event.type === 'LADDER') {
        this.router.navigate(['/event/ladderByCode', this.code]);
      }
    }
  }

}
