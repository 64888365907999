<div class="edit-ladder-step flex justify-content-center flex-1">
  <div class="edit-ladder-step-panel center-panel large form-panel">
    <form [formGroup]="stepForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-column gap-3">
        <div class="flex flex-column gap-2 flex-1">
          <label class="required" htmlFor="name">Nom</label>
          <input id="name" type="text" pInputText formControlName="name"/>
        </div>
        <div class="flex flex-column gap-2 flex-1">
          <label htmlFor="position">Position</label>
          <input id="position" type="text" pInputText formControlName="position"/>
        </div>
        <div class="flex flex-column gap-2 flex-1">
          <label htmlFor="code">Image</label>
          <div class="flex flex-row align-items-center gap-3">
            <p-fileUpload #imageUpload class="uploader" accept="image/*" [maxFileSize]="1000000" mode="basic"
                    [auto]="true" chooseLabel="Parccourir" [customUpload]="true" (uploadHandler)="uploadFileHandler($event)"
                    invalidFileSizeMessageSummary="'{0}: Fichier invalide, '" invalidFileSizeMessageDetail="'la taille maximum est de {0}.'"
                    invalidFileTypeMessageSummary="'{0}: Fichier invalide, '" invalidFileTypeMessageDetail="'les types de fichiers autorisés sont: {0}.'"></p-fileUpload>
            <span class="text-field">{{ imageName }}</span>
          </div>
          <img src="{{ imageDataUrl }}" class="event-image" width="500" />
        </div>
      </div>
      <div class="separtor-line"></div>
      <div class="flex justify-content-end mt-3 gap-3">
        <button class="p-button-success" pButton type="submit" [disabled]="!stepForm.valid">{{mode === 'CREATE' ? 'Ajouter' : 'Enregistrer'}}</button>
      </div>
    </form>
  </div>
</div>
