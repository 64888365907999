<div class="create-event flex justify-content-center flex-1">
  <div class="create-event-panel center-panel large form-panel">
    <div class="flex flex-row">
      <div class="flex flex-grow-1"><span class="block-title">{{ event?.id ? 'Edition de l\'événement' : 'Nouvel événement' }}</span></div>
      <div *ngIf="mode === 'EDIT'" class="title-action" (click)="showDetail()"><em class="pi pi-eye"></em></div>
    </div>
    <div class="separtor-line"></div>
    <p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
    <form [formGroup]="eventForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-column gap-3">
        <div class="flex flex-row align-items-end justify-content-between gap-4 flex-1">
          <div class="flex flex-row align-items-end gap-4 flex-1">
            <div class="flex flex-column gap-2">
              <label class="required" htmlFor="type">Compte</label>
              <p-dropdown formControlName="compte" [options]="comptes" (onChange)="onCompteChange()">
                <ng-template let-compte pTemplate="selectedItem">{{ compte.numero + ' - ' + compte.type }}</ng-template>
                <ng-template let-compte pTemplate="item">{{ compte.numero + ' - ' + compte.type }}</ng-template>
              </p-dropdown>
            </div>
            <div class="flex flex-column gap-2">
              <label class="required" htmlFor="nbParticipant">Nb de participants</label>
              <p-dropdown formControlName="nbParticipant" styleClass="w-13rem" [options]="participants" optionLabel="label" (onChange)="onParticipantChange()">
                <ng-template let-particitant pTemplate="selectedItem">{{ particitant.label }}</ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="flex flex-row gap-2 credit-info">
            <label>Nombre de crédits : </label><span class="text-field" [ngClass]="{'credit-empty' : creditCount === 0}">{{creditCount}}</span>
          </div>
          <div class="flex flex-column gap-2" *ngIf="userRights.admin">
            <label>Preset {{preset ? ': oui' : ': non'}}</label>
            <p-inputSwitch [(ngModel)]="preset" [ngModelOptions]="{standalone: true}"></p-inputSwitch>
          </div>
        </div>
        <div class="flex flex-row align-items-center gap-4 flex-1">
          <div class="flex flex-column gap-2 flex-1">
            <label class="required" htmlFor="name">Nom</label>
            <input id="name" type="text" pInputText formControlName="name"/>
          </div>
          <div class="flex flex-column gap-2">
            <label class="required" htmlFor="eventDate">Date</label>
            <p-calendar id="eventDate" formControlName="eventDate" [showIcon]="true" [dateFormat]="'dd/mm/yy'"></p-calendar>
          </div>
          <div class="flex flex-column gap-2">
            <label class="required" htmlFor="type">Type d'événement</label>
            <p-dropdown formControlName="type" [options]="types"></p-dropdown>
          </div>
        </div>
        <div class="flex flex-row align-items-start gap-4 flex-1">
          <div class="flex flex-column gap-2 flex-1">
            <label class="required" htmlFor="description">Description</label>
            <textarea id="description" rows="10" cols="30" pInputTextarea formControlName="description" ></textarea>
          </div>
          <div class="flex flex-column gap-2">
            <label htmlFor="image">Image de l'événement</label>
            <div class="flex flex-row align-items-center gap-3">
              <p-fileUpload #imageUpload class="uploader" accept="image/*" [maxFileSize]="1000000" mode="basic"
                      [auto]="true" chooseLabel="Parccourir" [customUpload]="true" (uploadHandler)="uploadFileHandler($event)"
                      invalidFileSizeMessageSummary="'{0}: Fichier invalide, '" invalidFileSizeMessageDetail="'la taille maximum est de {0}.'"
                      invalidFileTypeMessageSummary="'{0}: Fichier invalide, '" invalidFileTypeMessageDetail="'les types de fichiers autorisés sont: {0}.'"></p-fileUpload>
              <span class="text-field">{{ imageName }}</span>
            </div>
            <img src="{{ imageDataUrl ? imageDataUrl : defaultImageUri }}" class="event-image" />
          </div>
        </div>
        <div class="flex flex-row align-items-start gap-4 flex-1">
          <app-user-list [label]="'Managers'" [(users)]="managers"></app-user-list>
          <app-user-list [label]="'Invités'" [(users)]="guests"></app-user-list>
          <div class="flex flex-column gap-2 flex-grow-1 w-1rem">
            <label>{{ eventForm.get('publicEvent')?.value ? 'Publique' : 'Privé' }}</label>
            <p-inputSwitch formControlName="publicEvent"></p-inputSwitch>
          </div>
        </div>
      </div>
      <div class="separtor-line"></div>
      <div class="flex justify-content-end mt-3 gap-3">
        <button class="p-button-success" pButton type="submit" [disabled]="!eventForm.valid">Sauvegarder</button>
        <button pButton type="button" (click)="onConfigure()" [disabled]="!event?.id">Configurer</button>
      </div>
    </form>
  </div>
</div>
