import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { EventLadder } from "src/app/models/event.model";
import { Team } from "src/app/models/team.model";
import { TeamService } from "src/app/service/team.service";
import { EditTeamComponent } from "./edit-team/edit-team.component";
import { MessageService } from "primeng/api";

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnChanges {
  @Input() eventLadder?: EventLadder;
  @Output() addTeamChange = new EventEmitter<Team>();
  teams: Team[] = [];

  constructor(private teamService: TeamService, public dialogService: DialogService, private messageService: MessageService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventLadder']?.currentValue) {
      this.teamService.findByEventLadderId(this.eventLadder!.id!).subscribe(results => this.teams = results);
    }
  }

  ajouter() {
    const ref: DynamicDialogRef = this.dialogService.open(EditTeamComponent, {
      header: 'Ajouter une étape',
      data: {
        mode: 'CREATE',
        eventLadder: this.eventLadder,
        numberTeam: this.teams.length,
      }
    });

    ref.onClose.subscribe((team: Team) => {
      if (team) {
        this.addTeamChange.emit(team);
        this.teams = [...this.teams, team];
        this.teams.sort((t1, t2) => t1?.name && t2?.name ? t1.name.localeCompare(t2.name) : 0);
        this.messageService.add({ severity: 'success', summary: 'Créé', detail: 'l\'équipe a été ajouté.' });
      }
    });
  }

  modifier(team: Team) {
    const ref: DynamicDialogRef = this.dialogService.open(EditTeamComponent, {
      header: 'Modifier une étape',
      data: {
        mode: 'EDIT',
        eventLadder: this.eventLadder,
        numberTeam: this.teams.length,
        team: team,
      }
    });

    ref.onClose.subscribe((team: Team) => {
      if (team) {
        this.teams = [...this.teams.filter(t => t.id != team.id), team];
        this.teams.sort((t1, t2) => t1?.name && t2?.name ? t1.name.localeCompare(t2.name) : 0);
        this.messageService.add({ severity: 'success', summary: 'Modifié', detail: 'l\'équipe a été modifié.' });
      }
    });
  }
}
