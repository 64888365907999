import * as jsonImage from '../../../assets/images/images.json'

export class ImageUtils {
  static imageData: any = jsonImage;

  static getDefaultEvent(): string {
    return this.imageData.eventDefault;
  }

  static getPlaceholder(): string {
    return this.imageData.placeholder;
  }
}
