import { Component, OnInit } from '@angular/core';
import { Message, PrimeNGConfig } from 'primeng/api';
import { GlobalMessageService } from './service/globalMessage.service';
import { UserService } from './service/user.service';
import { takeUntil } from 'rxjs';
import { BaseComponent } from './component/shared/base.component';
import { AuthService } from './service/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {
  title = 'event-plateforme-front';
  userConnected: boolean = false;
  sidebarMenu: boolean = false;

  constructor(private globalMessageService: GlobalMessageService, private primengConfig: PrimeNGConfig,
    private userService: UserService, private authService: AuthService, private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.translateService.addLangs(['fr', 'en']);
    this.translateService.setDefaultLang('fr');

    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang?.match(/fr|en/) ? browserLang : 'fr');
    this.translate(browserLang ?? 'fr');

    this.userService.getUserConnected().pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => this.userConnected = !!user);
    this.authService.retrieveUserConnectedIfToken();
  }

  translate(lang: string) {
      this.translateService.use(lang);
      this.translateService.get('primeng').subscribe(res => this.primengConfig.setTranslation(res));
  }

  public get messages(): Message[] {
    return this.globalMessageService.messages;
  }

  public set messages(messages: Message[]) {
    this.globalMessageService.addMessages(messages);
  }
}
