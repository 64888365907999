import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Compte, Entreprise } from 'src/app/models/compte.model';
import { User } from 'src/app/models/user.model';
import { CompteService } from 'src/app/service/compte.service';
import { UserService } from 'src/app/service/user.service';
import { BaseComponent } from '../../shared/base.component';
import { of, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-create-compte',
  templateUrl: './create-compte.component.html',
  styleUrls: ['./create-compte.component.scss']
})
export class CreateCompteComponent extends BaseComponent implements OnInit {

  compte?: Compte;
  suggestions: User[] = [];
  collaborateurs: User[] = [];
  mode: 'CREATE' | 'EDIT' = 'CREATE';

  compteForm = this.fb.group({
    type: ['ENTREPRISE'],
    name: ['', Validators.required],
    numero: [''],
    responsable: new FormControl<User | undefined>(undefined, Validators.required),
    collaborateurs: [''],
  });

  constructor(private fb: FormBuilder, private userService: UserService, private compteService: CompteService,
              private messageService: MessageService, private router: Router, private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.route.params.pipe(
      switchMap(params => {
        if (params['id']) {
          this.mode = 'EDIT';
          return this.compteService.findById(params['id']);
        }
        return of(undefined);
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(compte => {
      if (compte) {
        this.compte = compte;
        this.updateForm(compte);
      }
    });
  }

  onSubmit() {
    if (this.compteForm.valid) {
      this.compteService.save(this.formToCompte()).subscribe(compteSaved => {
        this.compte = compteSaved;
        this.messageService.add({ severity: 'success', summary: 'Créé', detail: 'le compte à bien été créé' });
        this.router.navigate(['/compte']);
      });
    }
  }

  search(event: any) {
    if (event.query) {
      this.userService.findByNomPrenom(event.query).subscribe(results => this.suggestions = [...results]);
    }
  }

  getUserLabel(user: User) {
    if (user) {
      return user.prenom + ' ' + user.nom;
    }
    return '';
  }

  private updateForm(compte: Compte) {
    this.compteForm.patchValue({
      name: compte.entreprise?.name,
      numero: compte.entreprise?.numero,
      responsable: compte.entreprise?.responsable,
    });
    this.collaborateurs = compte.entreprise?.collaborateurs ?? [];
  }

  private formToCompte(): Compte {
    const compteToSave = new Compte();
    const entreprise = new Entreprise();

    if (this.compte) {
      compteToSave.id = this.compte.id;
      compteToSave.actif = this.compte.actif;
      compteToSave.creationDate = this.compte.creationDate;
      compteToSave.numero = this.compte.numero;
      entreprise.id = this.compte.entreprise?.id;
      entreprise.creationDate = this.compte.entreprise?.creationDate;
    }

    compteToSave.type = this.compteForm.get('type')?.value!;
    entreprise.name = this.compteForm.get('name')?.value!;
    entreprise.numero = this.compteForm.get('numero')?.value ?? undefined;
    entreprise.responsable = this.compteForm.get('responsable')?.value!;
    entreprise.collaborateurs = this.collaborateurs;
    compteToSave.entreprise = entreprise;

    return compteToSave;
  }
}
