import { User } from "./user.model";

export class Team {
  id?: number;
  name?: string;
  captain?: User;
  solo?: boolean;
  members: User[] = [];
}

export class TeamLadder {
  team?: Team;
  position?: number;
  teamName?: string;
  captainNom?: string;
  captainPrenom?: string;
  lastStepTime?: string;
  totalTime?: string;
}
