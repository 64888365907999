<div class="flex justify-content-center facture-content flex-1">
  <div class="center-panel large form-panel">
    <div><span class="block-title">Liste des facture</span></div>
    <div class="separtor-line"></div>
    <div class="flex align-items-center ">
      <div class="flex gap-2">
        <p-dropdown class="month-list" [(ngModel)]="month" [options]="monthList" (onChange)="onMonthChange()"></p-dropdown>
        <p-dropdown class="month-list" [(ngModel)]="year" [options]="yearList" (onChange)="onYearChange()"></p-dropdown>
        <p-checkbox [(ngModel)]="cancel" label="Annuler" [binary]="true" inputId="cancel" (onChange)="onChangeCancel()"></p-checkbox>
      </div>
      <div class="flex justify-content-end flex-1">
        <button class="p-button-success" pButton type="button" (click)="exporter()">Exporter</button>
      </div>
    </div>
    <div class="facture-list mt-2">
      <p-table [value]="commandes">
        <ng-template pTemplate="header">
          <tr>
            <th>Numéro</th>
            <th>Date</th>
            <th>Utilisateur</th>
            <th>Téléphone</th>
            <th>Entreprise</th>
            <th>Montant</th>
            <th>Payée</th>
            <th class="icon-action"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-commande>
          <tr>
            <td>{{ commande.numero }}</td>
            <td>{{ commande.dateCommande | date: 'dd/MM/yyyy' }}</td>
            <td>{{ commande.utilisateurName }}</td>
            <td>{{ commande.utilisateurPhone }}</td>
            <td>{{ commande.entreprise ? commande.entreprise : '-' }}</td>
            <td>${{ commande.prixTotal }}</td>
            <td [ngClass]="commande.solde ? 'valid' : 'unvalid'">{{ commande.solde ? 'Oui' : 'Non' }}</td>
            <td class="icon-action">
              <em class="pi pi-check-circle" pTooltip="Valider la facture" [showDelay]="500" (click)="valider(commande)"></em>
              <em class="pi pi-times-circle ml-2" pTooltip="Annuler la facture" [showDelay]="500" (click)="annuler(commande)"></em>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr><td colspan="8">Aucune factures</td></tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<p-confirmDialog></p-confirmDialog>
