<div class="flex justify-content-center compte-content flex-1">
  <div class="center-panel large form-panel ">
    <div><span class="block-title">Liste de vos comptes</span></div>
    <div class="separtor-line"></div>
    <div class="flex justify-content-end gap-3">
      <button class="p-button-success" pButton type="button" (click)="addCompte()" [disabled]="!currentUser || !currentUser.validate">Ajouter</button>
    </div>
    <div class="compte-list mt-2">
      <p-table [value]="comptes">
        <ng-template pTemplate="header">
          <tr>
            <th>Numéro</th>
            <th>Type</th>
            <th>propriétaire</th>
            <th>date de création</th>
            <th>Actif</th>
            <th class="icon-action"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-compte>
          <tr>
            <td>{{ compte.numero }}</td>
            <td>{{ compte.type }}</td>
            <td>{{ compte.type === 'ENTREPRISE' ? compte.entreprise.name : compte.personne.prenom + ' ' + compte.personne.nom }}</td>
            <td>{{ compte.creationDate | date: 'dd/MM/yyyy' }}</td>
            <td><em class="pi pi-verified" [ngClass]="compte.actif ? 'verify' : 'unverify'"
              pTooltip="Votre compte d'entreprise doit être validé par un responsable de la plateforme" [showDelay]="500" [tooltipDisabled]="compte.actif"></em></td>
            <td class="icon-action"><em class="pi pi-pencil" [ngClass]="{'disabled' : compte.type === 'PARTICULIER'}" (click)="modify(compte)"
              pTooltip="Les comptes de particulier ne sont pas éditable" [showDelay]="500" [tooltipDisabled]="compte.type === 'ENTREPRISE'"></em></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr><td colspan="6">Aucun comptes</td></tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
