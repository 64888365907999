<div class="create-commande flex justify-content-center flex-1">
  <div class="create-commande-panel center-panel large form-panel">
    <div><span class="block-title">Nouvelle commande</span></div>
    <div class="separtor-line"></div>
    <div><p><em class="pi pi-info-circle"></em> Cette commande vous donnera accès à un nombre de crédits à utiliser dans la création d'événements. Ces crédits sont valable 7 jours à compté de la validation de la commande.</p></div>
    <form [formGroup]="commandeForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-column gap-3">
        <div class="flex flex-row align-items-center gap-4 flex-1">
          <div class="flex flex-column gap-2">
            <label class="required" htmlFor="type">Compte</label>
            <p-dropdown formControlName="compte" [options]="comptes">
              <ng-template let-compte pTemplate="selectedItem">{{ compte.numero + ' - ' + compte.type}}</ng-template>
              <ng-template let-compte pTemplate="item">{{ compte.numero + ' - ' + compte.type}}</ng-template>
            </p-dropdown>
          </div>
        </div>
        <div class="flex flex-column gap-2 flex-1">
          <div class="flex justify-content-end gap-3 flex-1">
            <button class="p-button-success" pButton type="button" (click)="addLigneCommande()">Ajouter</button>
          </div>
          <div class="commandeLine-list">
            <p-table [value]="commandeLines">
              <ng-template pTemplate="header">
                <tr>
                  <th>Description</th>
                  <th class="w-10rem">Nb participants</th>
                  <th class="w-10rem">Prix</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-commandeLine>
                <tr>
                  <td>{{ commandeLine.tarif.description }}</td>
                  <td>{{ commandeLine.tarif.nombre }}</td>
                  <td>${{ commandeLine.tarif.prix }}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="summary">
                <div class="flex justify-content-end">
                  <span>Montant total : ${{prixTotal}}</span>
                </div>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr><td colspan="7">Aucune lignes de commande</td></tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end mt-3 gap-2">
        <p-checkbox [(ngModel)]="checkedCGU" [binary]="true" inputId="binary" label="Accepter les conditions d'utilisation."
                    [ngModelOptions]="{standalone: true}"></p-checkbox>
        <em class="pi pi-info-circle align-self-center text-label info-cgu" pTooltip="Voir les CGU" [showDelay]="500" (click)="displayCGU()"></em>
      </div>
      <div class="separtor-line"></div>
      <div class="flex justify-content-end mt-3 gap-3">
        <button class="p-button-success" pButton type="submit" [disabled]="!checkedCGU || !commandeForm.valid || commandeLines.length === 0">Valider la commande</button>
      </div>
    </form>
  </div>
</div>
