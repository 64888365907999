import { ImageData } from "./data.model";
import { User } from "./user.model";

// export type EventType = 'JEU_PISTE_IMAGE';

export enum EventType {
  JEU_PISTE_IMAGE,
}

export enum EventStatus {
  CREATED,
  CONFIG,
  READY,
  STARTED,
  FINISHED,
}

export class Event {
  id?: number;
  name?: string;
  description?: string;
  code?: string;
  imageData?: ImageData;
  ownerId?: number;
  ownerName?: string;
  eventDate?: string;
  startDate?: string;
  type?: string;
  status?: string;
  managers?: User[];
  guests?: User[];
  publicEvent?: boolean;
  compteId?: number;
  nbParticipant?: number;
  preset?: boolean;
}

export class EventPiste {
  id?: number;
  event?: Event;
  steps?: EventPisteSteps[];
}

export class EventPisteSteps {
  id?: number;
  ordre?: number;
  code?: string;
  indice?: string;
  imageData?: ImageData;
  eventPisteId?: number;
}

export class StatusEvent {
  code?: string;
  labal?: string;
}

export class EventConfigDetail {
  type?: string;
  stepCount?: number;
  firstCode?: string;
}

export class EventPisteScore {
  teamId?: number;
  teamName?: string;
  userName?: string;
  totalTime?: string;
  stepTimes?: string[];
  solo: boolean = false;
}

export class EventLadder {
  id?: number;
  event?: Event;
  steps?: EventLadderStep[];
}

export class EventLadderStep {
  id?: number;
  ordre?: number;
  name?: string;
  position?: string;
  type?: string;
  imageData?: ImageData;
  eventLadderId?: number;
}

export class EventLadderScore {
  position?: number;
  teamId?: number;
  teamName?: string;
  captainName?: string;
  stepNames: string[] = [];
  totalTime?: string;
  stepTimes?: string[] = [];
  totalPoint?: number;
  stepPoints?: number[] =[];
  type?: string;
  nbStep?: number;
  finished: boolean = false;
}
