import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap, takeUntil } from 'rxjs';
import { Event } from 'src/app/models/event.model';
import { EventService } from 'src/app/service/event.service';
import { UserService } from 'src/app/service/user.service';
import { ImageUtils } from 'src/app/shared/utils/Image.utils';
import { BaseComponent } from '../shared/base.component';
import { DialogService } from 'primeng/dynamicdialog';
import { EventConfigDetailComponent } from './event-config-detail/event-config-detail.component';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent extends BaseComponent implements OnInit {
  defaultImageUri?: string;
  events: Event[] = [];

  constructor(private eventService: EventService, private userService: UserService, private router: Router, public dialogService: DialogService) {
    super();
  }

  ngOnInit(): void {
    this.defaultImageUri = ImageUtils.getDefaultEvent();
    this.userService.getUserConnected().pipe(
      switchMap(user => {
        if (user?.id) {
          return this.eventService.findAllByUserId(user.id);
        }
        return [];
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(events => this.events = events);
  }

  edit(event: Event) {
    if (event) {
      this.router.navigate(['/event/edit', event.id]);
    }
  }

  ready(event: Event) {
    if (event && event.id) {
      event.status = 'READY';
      this.eventService.updateStatusReady(event.id, true).subscribe();
    }
  }

  notReady(event: Event) {
    if (event && event.id) {
      event.status = 'CREATED';
      this.eventService.updateStatusReady(event.id, false).subscribe();
    }
  }

  endEvent(event: Event) {
    if (event && event.id) {
      this.eventService.updateStatus(event.id!, 'FINISHED')
        .subscribe(status => event!.status = status.code);
    }
  }

  addEvent() {
    this.router.navigate(['/event/create']);
  }

  showDetail(event: Event) {
    this.dialogService.open(EventConfigDetailComponent, {
      header: "Détail de la configuration de l'événement",
      width: '50vw',
      data: {
        eventId: event?.id,
      }
    });
  }
}
