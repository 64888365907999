import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Tarif } from 'src/app/models/commande.model';
import { Compte } from 'src/app/models/compte.model';
import { User } from 'src/app/models/user.model';
import { TarifService } from 'src/app/service/tarif.service';
import { CguDetailComponent } from '../../shared/cgu-detail/cgu-detail.component';

@Component({
  selector: 'app-create-event-dialog',
  templateUrl: './create-event-dialog.component.html',
  styleUrls: ['./create-event-dialog.component.scss']
})
export class CreateEventDialogComponent implements OnInit {
  nbParticipant: number = 0;
  typeEvent?: string;
  tarif?: Tarif;
  nomtantEvent: {type: string, nombre: number, prix: number}[] = [];
  checkedCGU: boolean = false;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private tarifService: TarifService, public dialogService: DialogService) {}

  ngOnInit(): void {
    if (this.config.data) {
      this.nbParticipant = this.config.data.nbParticipant;
      this.typeEvent = this.config.data.typeEvent;

      this.tarifService.findByNbParticipant(this.nbParticipant).subscribe(result => {
        if (result) {
          this.tarif = result;
          this.nomtantEvent.push({type: this.typeEvent ?? '', nombre: this.nbParticipant ?? 0, prix: this.tarif!.prix ?? 0});
        }
      });
    }
  }

  displayCGU() {
    this.dialogService.open(CguDetailComponent, {header: "Conditions générales d'utilisation"});
  }

  validate() {
    this.ref.close(true);
  }

  close() {
    this.ref.close(false);
  }
}
