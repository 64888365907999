import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-home-left',
  templateUrl: './home-left.component.html',
  styleUrls: ['./home-left.component.scss']
})
export class HomeLeftComponent {
  @Input() userConnected?: User;

  constructor(private router: Router) {}

  openCommande() {
    this.router.navigate(['/commande']);
  }

  openEvent() {
    this.router.navigate(['/event/create']);
  }

  openEventList() {
    this.router.navigate(['/event']);
  }

  openEventReady() {
    this.router.navigate(['/event/eventReady']);
  }
}
