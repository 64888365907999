import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { EventLadder, EventLadderScore } from "src/app/models/event.model";
import { Team } from "src/app/models/team.model";
import { User } from "src/app/models/user.model";
import { EventService } from "src/app/service/event.service";
import { TeamService } from "src/app/service/team.service";

@Component({
  selector: 'app-ladder-suivi',
  templateUrl: './ladder-suivi.component.html',
  styleUrls: ['./ladder-suivi.component.scss']
})
export class LadderSuiviComponent implements OnChanges {
  @Input() user?: User;
  @Input() eventLadder?: EventLadder;

  team?: Team;
  teamScore?: EventLadderScore;
  type?: string;
  timeLineValues: {name: string, value: string}[] = [];

  constructor(private eventService: EventService, private teamService: TeamService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventLadder']?.currentValue) {
      if (this.eventLadder?.steps && this.eventLadder?.steps?.length > 0) {
        this.type = this.eventLadder?.steps[0].type;
      }
      this.loadTeam();
      this.refreshScores();
    }
    if (changes['user']?.currentValue) {
      this.loadTeam();
    }
  }

  refreshScores() {
    if (this.eventLadder && this.team) {
      this.eventService.findLadderScoreByEventLadderIdTeamId(this.eventLadder.id!, this.team.id!).subscribe(result => {
        this.teamScore = result;
        if (result) {
          this.timeLineValues = [];
          result.stepNames.forEach((stepName: string, index: number) => {
            let value = '';
            if (this.type === 'TIME') {
              value = result.stepTimes ? result.stepTimes[index] : '';
            } else {
              value = result.stepPoints ? String(result.stepPoints[index]) : '';
            }
            this.timeLineValues.push({name: stepName, value: value});
          });
        }
      });
    }
  }

  private loadTeam() {
    if(!this.team && this.user && this.eventLadder) {
      this.teamService.findByUserIdEventLadderId(this.user?.id!, this.eventLadder?.id!)
        .subscribe(result => this.team = result);
    }
  }

}
