<div class="preset-jeu-piste flex justify-content-center flex-1 h-full">
  <div class="preset-jeu-piste-panel center-panel large h-full">
    <div class="flex flex-column justify-content-between h-full">
      <div class="flex flex-column gap-3 overflow-hidden">
        <div class="flex flex-row align-items-center gap-4 flex-1 p-2">
          <div class="flex flex-column gap-2">
            <label htmlFor="preset">Présélections</label>
            <p-dropdown id="preset" [(ngModel)]="selectedEvent" [options]="events" (onChange)="onEventChange()" optionLabel="name"
                        [showClear]="true" placeholder="Choisir une présélection" appendTo="body">
            </p-dropdown>
          </div>
        </div>
        <div class="flex flex-column gap-3 overflow-y-auto overflow-x-hidden">
          <div class="grid px-2">
            <div class="col-6" *ngFor="let step of steps">
              <p-card header="Code : {{ step.code }}">
                <ng-template pTemplate="header">
                  <img src="{{ step.imageData?.miniData }}" />
                </ng-template>
                <p>Indice : {{ step.indice }}</p>
              </p-card>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-column">
        <div class="separtor-line"></div>
        <div class="flex justify-content-end gap-3">
          <button class="p-button-success" pButton type="button" (click)="addStep()" [disabled]="!selectedEvent || saving">Ajouter</button>
        </div>
      </div>
    </div>
  </div>
</div>
