import { Component, OnInit } from '@angular/core';
import { Event } from 'src/app/models/event.model';
import { EventService } from 'src/app/service/event.service';
import { ImageUtils } from 'src/app/shared/utils/Image.utils';
import { CopyPresetComponent } from '../copy-preset/copy-preset.component';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-preset-list',
  templateUrl: './preset-list.component.html',
  styleUrls: ['./preset-list.component.scss']
})
export class PresetListComponent implements OnInit {
  defaultImageUri?: string;
  events: Event[] = [];

  constructor(private eventService: EventService, public dialogService: DialogService) {}

  ngOnInit(): void {
    this.defaultImageUri = ImageUtils.getDefaultEvent();
    this.eventService.findAllPreset().subscribe(results => this.events = results);
  }

  copy(event: Event) {
    this.dialogService.open(CopyPresetComponent, {
      header: 'Copie du préset',
      width: '50%',
      data: {
        preset: event,
      }
    });
  }
}
