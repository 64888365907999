<div class="ladder-event flex justify-content-center flex-1">
  <div class="center-panel large form-panel">
    <div><span class="block-title">Configuration du classement</span></div>
    <div class="separtor-line"></div>
    <div class="flex flex-column gap-4">
      <div class="flex flex-row gap-4 align-items-center">
        <div class="flex flex-column gap-2">
          <label htmlFor="type">Classement par</label>
          <p-dropdown class="type" [(ngModel)]="typeClassement" [options]="types">
          </p-dropdown>
        </div>
      </div>
      <div class="flex flex-column gap-2">
        <div class="flex align-items-end">
          <div class="flex"><span class="block-title subtitle">Liste des étapes</span></div>
          <div class="flex justify-content-end flex-1"><button class="p-button-success" pButton type="button" (click)="ajouter()">Ajouter</button></div>
        </div>
        <div class="flex flex-column">
          <p-table [value]="steps">
            <ng-template pTemplate="header">
              <tr>
                <th class="th-num">Numéro</th>
                <th>Nom</th>
                <th>Position</th>
                <th class="th-image">Image</th>
                <th class="th-action"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-step>
              <tr>
                <td>{{ step.ordre }}</td>
                <td>{{ step.name }}</td>
                <td>{{ step.position }}</td>
                <td class="flex align-items-center td-image"><img *ngIf="step.imageData.miniData" src="{{ step.imageData.miniData }}" height="37px"></td>
                <td class="icon-action"><em class="pi pi-file-edit"(click)="modifier(step)"></em></td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
