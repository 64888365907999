<div class="valid-ladder-ckpt flex justify-content-center flex-1">
  <div class="valid-ladder-ckpt-panel center-panel form-panel">
    <div class="flex flex-column gap-3">
      <p>{{ message }}</p>
      <div *ngIf="type === 'POINT'" class="flex flex-column gap-2 flex-1">
        <label class="required" htmlFor="point">Nombre de points</label>
        <p-inputNumber inputId="point" [(ngModel)]="point" (ngModelChange)="isPointValid()" />
      </div>
    </div>
    <div class="separtor-line"></div>
    <div class="flex justify-content-end mt-3 gap-3">
      <button class="p-button-success" pButton type="button" [disabled]="!stepValid" label="Valider" (click)="valider()"></button>
      <button class="p-button-success" pButton type="button" (click)="close()">Fermer</button>
    </div>
  </div>
</div>
