import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Event, EventPiste, EventPisteSteps } from 'src/app/models/event.model';
import { EventService } from 'src/app/service/event.service';

@Component({
  selector: 'app-preset-jeu-piste',
  templateUrl: './preset-jeu-piste.component.html',
  styleUrls: ['./preset-jeu-piste.component.scss']
})
export class PresetJeuPisteComponent implements OnInit {
  events: Event[] = [];
  selectedEvent?: Event;
  steps: EventPisteSteps[] = [];
  eventPiste?: EventPiste;
  saving: boolean = false;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private eventService: EventService) {
  }

  ngOnInit(): void {
    if (this.config.data) {
      this.eventPiste = this.config.data.eventPiste;
    }
    this.eventService.findByPresetAndType('JEU_PISTE_IMAGE').subscribe(results => this.events = results);
  }

  onEventChange() {
    if (this.selectedEvent) {
      this.eventService.findStepByEventId(this.selectedEvent.id!).subscribe(results => this.steps = results);
    } else {
      this.steps = [];
    }
  }

  addStep() {
    this.saving = true;
    this.eventService.copyStepsFromEvent(this.eventPiste?.id!, this.selectedEvent?.id!).subscribe(results => {
      this.saving = false;
      this.ref.close(results);
    });
  }
}
