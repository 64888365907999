import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService, private userService: UserService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      if (param && param['accessToken'] &&  param['refreshToken']) {
        const username = this.authService.login({accessToken: param['accessToken'], refreshToken: param['refreshToken']});
        if (username) {
          this.userService.setUserConnected(username).subscribe();
        }
      }
      this.router.navigate(['/']);
    });
  }
}
