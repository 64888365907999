<div class="flex flex-column ladder-suivi-content gap-3">
  <div class="flex flex-row align-items-center justify-content-between info-panel">
    <span class="info-text">Suivi des résultats de l'équipe {{ team?.name }}</span>
    <p-button icon="pi pi-refresh" (onClick)="refreshScores()" />
  </div>
  <div class="flex flex-row gap-3">
    <div class="flex flex-column gap-3 flex-1 info-panel">
      <div class="flex flex-row gap-2 info-text">
        <label>Equipe :</label>
        <span><strong>{{ team?.name }}</strong></span>
      </div>
      <div class="flex flex-row gap-2 info-text">
        <label>Equipe :</label>
        <span><strong>{{ team?.captain | username }}</strong></span>
      </div>
      <div class="flex flex-row gap-2 info-text">
        <label>Position :</label>
        <span><strong>{{ teamScore?.position }}</strong></span>
      </div>
      <div class="flex flex-row gap-2 info-text">
        <label>{{ type === 'TIME' ? 'Temps ' : 'Points '}}total :</label>
        <span><strong>{{ type === 'TIME' ? teamScore?.totalTime : teamScore?.totalPoint }}</strong></span>
      </div>
    </div>
    <div class="flex flex-column gap-3 flex-1 info-panel">
      <p-timeline [value]="timeLineValues">
        <ng-template pTemplate="content" let-event>
          <span class="info-text"><strong>{{ event.value }}</strong></span>
        </ng-template>
        <ng-template pTemplate="opposite" let-event>
          <span class="info-text">{{ event.name }}</span>
        </ng-template>
    </p-timeline>
    </div>
  </div>
</div>
