<div class="flex flex-column flex-1 home-right-content gap-5">
  <div class="flex flex-column last-preset info-panel">
    <div class="flex justify-content-center"><span class="block-title subtitle">Les derniers présets</span></div>
    <div>
      <p-carousel [value]="events" [numVisible]="1" [numScroll]="1" [circular]="true" orientation="vertical" [autoplayInterval]="10000" verticalViewPortHeight="270px">
        <ng-template let-event pTemplate="item">
          <div class="flex flex-column">
            <p-card header="{{ event.name }}">
              <ng-template pTemplate="header">
                <img src="{{ event.imageData?.data ?? defaultImageUri }}" class="event-image" />
              </ng-template>
              <ng-template pTemplate="footer">
                <div class="flex flex-row justify-content-center gap-3" *ngIf="userConnected?.validate">
                  <div class="icon-button" pTooltip="Créer un événement à partir de ce préset" (click)="duplicate(event)"><em class="pi pi-calendar-plus"></em></div>
                  <div class="icon-button" pTooltip="Afficher la liste des présets" (click)="openPresets()"><em class="pi pi-th-large"></em></div>
                </div>
              </ng-template>
            </p-card>
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>
  <div class="flex flex-column info-panel" *ngIf="userConnected">
    <div class="flex justify-content-center"><span class="block-title subtitle">Les tarifs</span></div>
    <div class="flex justify-content-center button-action"><p-button icon="pi pi-money-bill" label="Voir les tarifs" (onClick)="openTarif()"></p-button></div>
  </div>
</div>
