<div class="event-home flex flex-row">
  <div class="flex home-left"><app-home-left [userConnected]="userConnected"></app-home-left></div>
  <div class="flex home-center flex-column">
    <div class="flex justify-content-center"><span class="block-title">Bienvenu sur notre plateforme de gestion d'événements !</span></div>
    <div class="flex flex-row gap-3">
      <div class="pt-5"><img src="../../../assets/images/Logo_name_150.png" width="120" /></div>
      <div class="mt-2 block-text-justify"><p>Vous retrouverez ici tous vos événements, que vous en soyez un des initiateurs ou que vous y soyez invité.
        Ainsi que tous les événements publique créés par d'autres personnes.</p>
        <p>Il vous suffit de vous connecter avec votre compte Discord afin de pouvoir profiter des fonctionnalités de notre plateforme.
        <b>Pensez à compléter votre profile (certaines actions ne sont pas possible si non)</b> une fois votre autentification effectuée afin de finaliser votre compte sur la plateforme via le menu en cliquant sur votre avatar en haut à droite.
        Cela permettra également à d'autres utilisateurs de pouvoir vous inviter à leurs événements.</p></div>
    </div>
    <div class="flex flex-column mt-3">
      <app-text-editor *ngIf="editNews" [newsToModify]="newsToModify" (editChange)="edition($event)"></app-text-editor>
      <app-news-viewer *ngIf="!editNews" [isAdmin]="userRights.admin" (addChange)="edition(true)" (editChange)="modifyNews($event)"></app-news-viewer>
    </div>
  </div>
  <div class="flex home-right"><app-home-right [userConnected]="userConnected"></app-home-right></div>
</div>
