import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { News } from "../models/news.model";

@Injectable({providedIn: 'root'})
export class HomeService {
  constructor(private http: HttpClient) {}

  findLastPreset(): Observable<Event[]> {
    return this.http.get<Event[]>('/home/findLastPreset');
  }

  lastNews(): Observable<News[]> {
    return this.http.get<News[]>('/home/lastNews');
  }

}
