import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

export class ValidLadderCkptResult {
  type?: string;
  point?: number;
}

@Component({
  selector: 'app-valid-ladder-ckpt',
  templateUrl: './valid-ladder-ckpt.component.html',
  styleUrls: ['./valid-ladder-ckpt.component.scss']
})
export class ValidLadderCkptComponent implements OnInit {
  stepValid: boolean = false;
  message?: string;
  type?: string;
  point: number = 0;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data) {
      this.type = this.config.data.type;
      if (this.config.data.type === 'TIME') {
        this.message = "Voulez-vous valider le temps de l'étape pour l'équipe " + this.config.data.team + ' ?';
        this.stepValid = true;
      } else {
        this.message = "Saisissez les points de l'étape pour l'équipe " + this.config.data.team + ' ?';
      }
    }
  }

  isPointValid() {
    this.stepValid = !!this.point;
  }

  valider() {
    this.ref.close({type: this.config.data.type, point: this.point});
  }

  close() {
    this.ref.close();
  }
}
