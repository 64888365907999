import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-cgu-detail',
  templateUrl: './cgu-detail.component.html',
  styleUrls: ['./cgu-detail.component.scss']
})
export class CguDetailComponent {
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
  }
}
