import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { ConfirmationService } from 'primeng/api';
import { Commande } from 'src/app/models/commande.model';
import { CommandeService } from 'src/app/service/commande.service';

@Component({
  selector: 'app-facture',
  templateUrl: './facture.component.html',
  styleUrls: ['./facture.component.scss']
})
export class FactureComponent implements OnInit {
  commandes: Commande[] = [];
  month: number = 1;
  monthList: {label: string, value: number}[] = [{label: 'Janvier', value: 1}, {label: 'février', value: 2},
                                                 {label: 'Mars', value: 3}, {label: 'Avril', value: 4},
                                                 {label: 'Mai', value: 5}, {label: 'Juin', value: 6},
                                                 {label: 'Juillet', value: 7}, {label: 'Août', value: 8},
                                                 {label: 'Septembre', value: 9}, {label: 'Octobre', value: 10},
                                                 {label: 'Novembre', value: 11}, {label: 'Décembre', value: 12}];
  year: number = DateTime.now().year;
  yearList: {label: string, value: number}[] = [];
  cancel: boolean = false;

  constructor(private commandeService: CommandeService, private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    this.month = DateTime.now().month;
    this.yearList = [{label: String(this.year), value: this.year}, {label: String(this.year - 1), value: this.year - 1}];
    this.commandeService.findFacturesByDate(this.month, this.year, this.cancel).subscribe(results => this.commandes = [...results]);
  }

  valider(commande: Commande) {
    commande.solde = true;
    this.commandeService.validePaiement(commande.id!).subscribe();
  }

  annuler(commande: Commande) {
    this.confirmationService.confirm({
      message: 'Etes-vous sûr de vouloir annuler cette commande ?',
      header: 'Annulation confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon:"none",
      rejectIcon:"none",
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-danger',
      accept: () => {
        if (commande.id) {
          this.commandeService.cancelCommande(commande.id).subscribe(() => {
            this.commandeService.findFacturesByDate(this.month, this.year, this.cancel).subscribe(results => this.commandes = [...results]);
          });
        }
      },
    });
  }

  onMonthChange() {
    this.commandeService.findFacturesByDate(this.month, this.year, this.cancel).subscribe(results => this.commandes = [...results]);
  }

  onYearChange() {
    this.commandeService.findFacturesByDate(this.month, this.year, this.cancel).subscribe(results => this.commandes = [...results]);
  }

  onChangeCancel() {
    this.commandeService.findFacturesByDate(this.month, this.year, this.cancel).subscribe(results => this.commandes = [...results]);
  }

  exporter() {

  }
}
