<div class="flex flex-column copy-preset-content flex-1">
  <div class="flex flex-row align-items-center gap-4 flex-1">
    <div class="flex flex-column gap-2">
      <label class="required" htmlFor="type">Compte</label>
      <p-dropdown [(ngModel)]="compte" [options]="comptes" (onChange)="onCompteChange()">
        <ng-template let-compte pTemplate="selectedItem">{{ compte.numero + ' - ' + compte.type}}</ng-template>
        <ng-template let-compte pTemplate="item">{{ compte.numero + ' - ' + compte.type}}</ng-template>
      </p-dropdown>
    </div>
    <div class="flex flex-column gap-2">
      <label class="required" htmlFor="type">Nb de participants</label>
      <p-dropdown [(ngModel)]="nbParticipant" [options]="participants" optionLabel="label" (onChange)="onParticipantChange()">
        <ng-template let-particitant pTemplate="selectedItem">{{ particitant.label }}</ng-template>
      </p-dropdown>
    </div>
  </div>
  <div>
    <p>Vous allez créer un événement à partir du préset "{{ preset?.name }}" avec tous ses paramètres, vous pourrez le modifier par la suite. Souhaitez-vous créer ce nouvel événement ?</p>
  </div>
  <div class="flex flex-column" *ngIf="creditCount === 0">
    <p>Vous ne possédez pas suffisamment de crédits pour le nombre de participants que vous avez choisir, une commande serra éditée pour vous au tarif en vigueur.</p>
    <div class="flex flex-1">
      <p-table [value]="nomtantEvent" dataKey="id" class="flex-grow-1">
        <ng-template pTemplate="header">
          <tr>
            <th>Evénement type</th>
            <th class="w-10rem">Nb participants</th>
            <th class="w-10rem">Prix</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-montant>
          <tr>
            <td>{{ montant.type }}</td>
            <td>{{ montant.nombre === 0 ? 'Illimité' : montant.nombre }}</td>
            <td>${{ montant.prix }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr><td colspan="8">Aucun tarif</td></tr>
        </ng-template>
      </p-table>
    </div>
    <div class="flex justify-content-end mt-3 gap-2">
      <p-checkbox [(ngModel)]="checkedCGU" [binary]="true" inputId="binary" label="Accepter les conditions d'utilisation."
                  [ngModelOptions]="{standalone: true}"></p-checkbox>
      <em class="pi pi-info-circle align-self-center text-label info-cgu" pTooltip="Voir les CGU" [showDelay]="500" (click)="displayCGU()"></em>
    </div>
  </div>
  <div class="flex flex-column">
    <div class="separtor-line"></div>
    <div class="flex justify-content-end gap-3">
      <button class="p-button-success" pButton type="button" (click)="copyPreset()" [disabled]="creditCount === 0 && !checkedCGU">Oui</button>
      <button class="p-button-success" pButton type="button" (click)="close()">Non</button>
    </div>
  </div>
</div>
