<div class="photo-card">
  <p-card>
    <ng-template pTemplate="header">
      <img *ngIf="!step?.imageData?.miniData" src="{{ placeholder }}" class="photo-mini" />
      <p-image *ngIf="step?.imageData?.miniData" src="{{ step?.imageData?.miniData }}" previewImageSrc="{{ step?.imageData?.data }}" alt="Image" [preview]="true"></p-image>
    </ng-template>
    <div class="flex flex-column" *ngIf="started">
      <input type="text" pInputText [(ngModel)]="code" (ngModelChange)="onChangeInput($event)" />
      <p>Indice : {{ step?.indice ? step?.indice : 'Aucun' }}</p>
    </div>
  </p-card>
</div>
