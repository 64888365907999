import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { BaseComponent } from '../shared/base.component';
import { takeUntil } from 'rxjs';
import { User, UserRights } from 'src/app/models/user.model';
import { News } from 'src/app/models/news.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {

  userConnected?: User;
  editNews: boolean = false;
  userRights: UserRights = {guest: true, user: false, admin: false};
  newsToModify?: News;

  constructor(private userService: UserService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.userService.getUserConnected().pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      if (user) {
        this.userConnected = user;
        if(!user.validate) {
          this.router.navigate(['/profile', user.name])
        }
      }
    });
    this.userService.getUserRights().pipe(takeUntil(this.ngUnsubscribe)).subscribe(rights => this.userRights = rights);
  }

  edition(edit: boolean) {
    this.editNews = edit;
    if (edit === false) {
      this.newsToModify = undefined;
    }
  }

  modifyNews(news: News) {
    this.editNews = true;
    this.newsToModify = news;
  }
}
