import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EventConfigDetail } from 'src/app/models/event.model';
import { EventService } from 'src/app/service/event.service';

@Component({
  selector: 'app-event-config-detail',
  templateUrl: './event-config-detail.component.html',
  styleUrls: ['./event-config-detail.component.scss']
})
export class EventConfigDetailComponent implements OnInit {

  configDetail?: EventConfigDetail;
  description: string = '';

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private eventService: EventService) {}

  ngOnInit(): void {
    if (this.config.data) {
      this.eventService.getConfigDetail(this.config.data.eventId).subscribe(result => {
        this.configDetail = result;
        this.updateDescription(this.configDetail.type!);
      });
    }
  }

  close() {
    this.ref.close(undefined);
  }

  private updateDescription(type: string) {
    switch (type) {
      case 'JEU_PISTE_IMAGE':
        this.description = 'Vous êtes actuellement sur un événement de type jeu de piste photos qui consiste à trouver des lieux à partir de photos. '
                        + "Chaque photo est débloquée par un code qui peut être donné par un organisateur ou trouvé à partir de l'indice sous la photo précédente. "
                        + 'Le premier code de cette série est : <b>' + (this.configDetail?.firstCode ?? 'non défini') + '</b>';
        break;

      default:
        break;
    }
  }

}
