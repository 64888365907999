<div class="flex flex-column config-detail-content flex-1">
  <div>
    <app-user-list [label]="'Utilisteurs autorisés à suivre'" [(users)]="users"></app-user-list>
  </div>
  <div class="flex flex-column">
    <div class="separtor-line"></div>
    <div class="flex justify-content-end gap-3">
      <button class="p-button-success" pButton type="button" (click)="validate()">Valider</button>
      <button class="p-button-success" pButton type="button" (click)="close()">Fermer</button>
    </div>
  </div>
</div>
