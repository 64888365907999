import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'app-text-editor-viewer',
  templateUrl: './text-editor-viewer.component.html',
  styleUrls: ['./text-editor-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextEditorViewerComponent {
  @Input() innerText: string = '';
}
