import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { News } from "src/app/models/news.model";
import { NewsService } from "src/app/service/news.service";

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnChanges {
  @Input() newsToModify?: News;
  @Output() editChange = new EventEmitter<boolean>();

  text: string = '';
  previousText: string = '';
  title: string = '';

  constructor(private newsService: NewsService){}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['newsToModify'] && changes['newsToModify'].currentValue) {
      this.title = this.newsToModify?.title ?? '';
      this.text = this.newsToModify?.content ?? '';
    }
  }

  preview() {
    console.log(this.text);
    this.previousText = this.text;
  }

  save() {
    let news: News;
    if (this.newsToModify) {
      news = {...this.newsToModify};
    } else {
      news = new News();
    }
    news.title = this.title;
    news.content = this.text;
    if (this.newsToModify) {
      this.newsService.update(news).subscribe(() => this.editChange.emit(false));
    } else {
      this.newsService.create(news).subscribe(() => this.editChange.emit(false));
    }
  }

  cancel() {
    this.editChange.emit(false);
  }
}
