import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Table } from "primeng/table";
import { EventLadder, EventLadderScore } from "src/app/models/event.model";
import { EventService } from "src/app/service/event.service";
import { ValidLadderCkptComponent, ValidLadderCkptResult } from "./valid-ladder-ckpt/valid-ladder-ckpt.component";
import { Observable, of, switchMap } from "rxjs";

@Component({
  selector: 'app-ladder-checkpoint',
  templateUrl: './ladder-checkpoint.component.html',
  styleUrls: ['./ladder-checkpoint.component.scss']
})
export class LadderCheckpointComponent implements OnChanges {
  @Input() eventLadder?: EventLadder;

  teamScores: EventLadderScore[] = [];
  type?: string;

  constructor(private eventService: EventService, public dialogService: DialogService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventLadder']?.currentValue) {
      if (this.eventLadder?.steps && this.eventLadder?.steps?.length > 0) {
        this.type = this.eventLadder?.steps[0].type;
      }
      this.refreshScores();
    }
  }

  globalFilter(table: Table, event: any) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  refreshScores() {
    if (this.eventLadder) {
      this.refreshLadderScores().subscribe();
    }
  }

  valider(score: EventLadderScore) {
    const ref: DynamicDialogRef = this.dialogService.open(ValidLadderCkptComponent, {
      header: "Valider l'étape pour la team",
      data: {
        type: score.type,
        team: score.teamName,
      }
    });

    ref.onClose.subscribe((result: ValidLadderCkptResult) => {
      if (result) {
        console.log(result);
        if (result.type === 'TIME') {
          this.eventService.validateLadderScoreTime(score.teamId!).pipe(
            switchMap(() => this.refreshLadderScores())
          ).subscribe();
        } else if (result.type === 'POINT') {
          this.eventService.validateLadderScorePoint(score.teamId!, result.point ?? 0).pipe(
            switchMap(() => this.refreshLadderScores())
          ).subscribe();
        }
      }
    });
  }

  private refreshLadderScores(): Observable<EventLadderScore[]> {
    return this.eventService.findLadderScoreByEventLadderId(this.eventLadder!.id!).pipe(switchMap(result => {
      this.teamScores = [...result];
      return of(result);
    }));
  }
}
