<div class="create-tarif flex justify-content-center flex-1">
  <div class="create-tarif-panel center-panel large form-panel">
    <div><span class="block-title">{{ tarif?.id ? 'Edition du tarif' : 'Nouveau tarif' }}</span></div>
    <div class="separtor-line"></div>
    <form [formGroup]="tarifForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-column gap-3">
        <div class="flex flex-row align-items-center gap-4 flex-1">
          <div class="flex flex-column gap-2">
            <label class="required" htmlFor="type">Prix</label>
            <p-inputNumber formControlName="prix" inputId="currency-us" prefix="$"></p-inputNumber>
          </div>
          <div class="flex flex-column gap-2">
            <label class="required" htmlFor="nombre">Nb personnes</label>
            <p-inputNumber id="nombre" formControlName="nombre"></p-inputNumber>
          </div>
          <div class="flex flex-column gap-2">
            <label htmlFor="recursif">Récursif</label>
            <p-inputSwitch id="recursif" formControlName="recursif"></p-inputSwitch>
          </div>
        </div>
        <div class="flex flex-row align-items-center gap-4 flex-1">
          <div class="flex flex-column gap-2 flex-1">
            <label class="required" htmlFor="description">Description</label>
            <textarea id="description" rows="10" cols="30" pInputTextarea formControlName="description"></textarea>
          </div>
        </div>
      </div>
      <div class="separtor-line"></div>
      <div class="flex justify-content-end mt-3 gap-3">
        <button class="p-button-success" pButton type="submit" [disabled]="!tarifForm.valid">Sauvegarder</button>
      </div>
    </form>
  </div>
</div>
