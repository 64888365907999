import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnChanges {
  @Input() users: User[] = [];
  @Input() label: string = 'Rechercher';

  @Output() usersChange = new EventEmitter<User[]>();

  innerUsers: User[] = [];
  selectedUser?: User;
  suggestions: User[] = [];

  constructor(private userService: UserService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['users'] && changes['users'].currentValue) {
      this.innerUsers = [...this.users];
    }
  }

  search(event: any) {
    if (event.query) {
      this.userService.findByNomPrenom(event.query).subscribe(results => this.suggestions = [...results]);
    }
  }

  getUserLabel(user: User) {
    if (user) {
      return user.prenom + ' ' + user.nom;
    }
    return '';
  }

  addUser() {
    if (this.selectedUser && !this.innerUsers.some(u => u.id === this.selectedUser?.id)) {
      this.innerUsers.push(this.selectedUser);
      this.usersChange.emit([...this.innerUsers]);
    }
    this.selectedUser = undefined;
  }

  remove(user: User) {
    this.innerUsers = [...this.innerUsers.filter(u => u.nom !== user.nom && u.prenom !== user.prenom)];
    this.usersChange.emit([...this.innerUsers]);
  }
}
