import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { News } from "src/app/models/news.model";
import { HomeService } from "src/app/service/home.service";

@Component({
  selector: 'app-news-viewer',
  templateUrl: './news-viewer.component.html',
  styleUrls: ['./news-viewer.component.scss']
})
export class NewsViewerComponent implements OnInit {
  @Input() isAdmin: boolean = false;
  @Output() addChange = new EventEmitter<void>();
  @Output() editChange = new EventEmitter<News>();

  newsList: News[] = [];

  constructor(private homeService: HomeService) {}

  ngOnInit(): void {
    this.homeService.lastNews().subscribe(results => this.newsList = results);
  }

  add() {
    this.addChange.emit();
  }

  edit(news: News) {
    this.editChange.emit(news);
  }
}
