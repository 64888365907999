import { RouterModule, Routes } from "@angular/router"
import { HomeComponent } from "./component/home/home.component";
import { AuthGuard } from "./shared/guard/auth.guard";
import { ProfileComponent } from "./component/profile/profile.component";
import { LoginComponent } from "./component/login/login.component";
import { EventComponent } from "./component/event/event.component";
import { CreateEventComponent } from "./component/event/create-event/create-event.component";
import { JeuPisteEventComponent } from "./component/event/jeu-piste-event/jeu-piste-event.component";
import { EventActifComponent } from "./component/event/event-actif/event-actif.component";
import { JeuPisteComponent } from "./component/play/jeu-piste/jeu-piste.component";
import { CompteComponent } from "./component/compte/compte.component";
import { CreateCompteComponent } from "./component/compte/create-compte/create-compte.component";
import { CommandeComponent } from "./component/commande/commande.component";
import { TarifComponent } from "./component/tarif/tarif.component";
import { AdminGuard } from "./shared/guard/admin.gaurd";
import { CreateTarifComponent } from "./component/tarif/create-tarif/create-tarif.component";
import { CreateCommandeComponent } from "./component/commande/create-commande/create-commande.component";
import { FactureComponent } from "./component/commande/facture/facture.component";
import { UsersComponent } from "./component/users/user-list/users.component";
import { PresetListComponent } from "./component/event/preset-list/preset-list.component";
import { EventUserListComponent } from "./component/event/event-user-list/event-user-list.component";
import { LadderStepComponent } from "./component/event/ladder/ladder-step/ladder-step.component";
import { LadderComponent } from "./component/play/ladder/ladder.component";
import { ScoreBoardComponent } from "./component/play/score-board/score-board.component";

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'profile/:username', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'compte', component: CompteComponent, canActivate: [AuthGuard] },
  { path: 'compte/create', component: CreateCompteComponent, canActivate: [AuthGuard] },
  { path: 'compte/edit/:id', component: CreateCompteComponent, canActivate: [AuthGuard] },
  { path: 'commande', component: CommandeComponent, canActivate: [AuthGuard] },
  { path: 'commande/create', component: CreateCommandeComponent, canActivate: [AuthGuard] },
  { path: 'facture', component: FactureComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'tarif', component: TarifComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'tarif/create', component: CreateTarifComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'tarif/edit/:id', component: CreateTarifComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'event', component: EventComponent, canActivate: [AuthGuard] },
  { path: 'event/create', component: CreateEventComponent, canActivate: [AuthGuard] },
  { path: 'event/edit/:id', component: CreateEventComponent, canActivate: [AuthGuard] },
  { path: 'event/jeupisteConfig/:id', component: JeuPisteEventComponent, canActivate: [AuthGuard] },
  { path: 'event/ladderConfig/:id', component: LadderStepComponent, canActivate: [AuthGuard] },
  { path: 'event/eventReady', component: EventActifComponent, canActivate: [AuthGuard] },
  { path: 'event/JeuDePiste/:id', component: JeuPisteComponent, canActivate: [AuthGuard] },
  { path: 'event/JeuDePisteByCode/:code', component: JeuPisteComponent, canActivate: [AuthGuard] },
  { path: 'event/ladder/:id', component: LadderComponent, canActivate: [AuthGuard] },
  { path: 'event/ladderByCode/:code', component: LadderComponent, canActivate: [AuthGuard] },
  { path: 'event/presets', component: PresetListComponent, canActivate: [AuthGuard] },
  { path: 'event/scoreBoard', component: ScoreBoardComponent, canActivate: [AuthGuard] },
  { path: 'event/users', component: EventUserListComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: ''},
];

export const AppRouting = RouterModule.forRoot(routes);
