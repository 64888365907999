import { Component, OnInit } from '@angular/core';
import { EventPiste } from 'src/app/models/event.model';
import { User } from 'src/app/models/user.model';
import { EventService } from 'src/app/service/event.service';
import { BaseComponent } from '../../shared/base.component';
import { of, switchMap, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ImageUtils } from 'src/app/shared/utils/Image.utils';
import { AuthService } from 'src/app/service/auth.service';
import { Team } from 'src/app/models/team.model';
import { TeamService } from 'src/app/service/team.service';

@Component({
  selector: 'app-jeu-piste',
  templateUrl: './jeu-piste.component.html',
  styleUrls: ['./jeu-piste.component.scss']
})
export class JeuPisteComponent extends BaseComponent implements OnInit {
  currentUser?: User;
  team?: Team;
  eventPiste?: EventPiste;
  placeholder?: string;
  isManager: boolean = false;
  onlyManager: boolean = false;

  constructor(private eventService: EventService, private authService: AuthService, private teamService: TeamService,
              private route: ActivatedRoute, private router: Router, private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    this.placeholder = ImageUtils.getPlaceholder();
    this.authService.getConnectedUser().pipe(
      switchMap(user => {
        this.currentUser = user;
        return this.route.params;
      }),
      switchMap(params => {
        if (this.currentUser?.id) {
          if (params['id']) {
            return this.eventService.findJeuPisteToPlay(Number(params['id']), this.currentUser.id);
          } else if (params['code']) {
            return this.eventService.findJeuPisteToPlayByCode(params['code']);
          }
        }
        return of(undefined);
      }),
      switchMap(eventPiste => {
        this.eventPiste = eventPiste;
        if (eventPiste && this.currentUser) {
          return this.teamService.findByUserIdEventPisteId(this.currentUser.id!, eventPiste.id!);
        }
        return of(undefined);
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(team => {
      this.team = team;
      if (this.eventPiste) {
        this.isManager = (this.eventPiste.event?.ownerId === this.currentUser?.id || this.eventPiste.event?.managers?.some(m => m.id === this.currentUser?.id)) ?? false;
        this.eventPiste.steps?.sort((s1, s2) => (s1.ordre && s2.ordre) ? s1.ordre - s2.ordre : 0);
        this.checkManagerOnly();
      } else {
        this.messageService.add({ severity: 'error', summary: 'Evénement', detail: 'l\'événement jeu de piste n\'existe pas ou vous n\'avez pas le droit d\'y assister.' });
        this.router.navigate(['/event/eventReady']);
      }
    });
  }

  refreshStatus() {
    if (this.eventPiste?.event) {
      this.eventService.getEventStatus(this.eventPiste.event.id!)
        .subscribe(status => this.eventPiste!.event!.status = status.code);
    }
  }

  startEvent() {
    if (this.eventPiste?.event) {
      this.eventService.updateStatus(this.eventPiste.event.id!, 'STARTED')
        .subscribe(status => this.eventPiste!.event!.status = status.code);
    }
  }

  endEvent() {
    if (this.eventPiste?.event) {
      this.eventService.updateStatus(this.eventPiste.event.id!, 'FINISHED')
        .subscribe(status => this.eventPiste!.event!.status = status.code);
    }
  }

  createTeam(team: Team) {
    if (this.eventPiste) {
      this.teamService.createTeamForEventPiste(team, this.eventPiste.id!).subscribe(result => this.team = result);
    }
  }

  jointTeam(team: Team) {
    if (team?.id && this.currentUser) {
      this.teamService.addUserToTeam(this.currentUser.id!, team.id).subscribe(result => this.team = result);
    }
  }

  managerOnly(only: boolean) {
    this.onlyManager = only;
  }

  checkManagerOnly() {
    if (this.eventPiste?.event) {
      const eventManager = localStorage.getItem('event.manager');
      this.onlyManager = !!eventManager && Number(eventManager) === this.eventPiste?.event.id;
    }
  }
}
