import { User } from "./user.model";

export class Compte {
  id?: number;
  type?: string;
  numero?: string;
  personne?: User;
  entreprise?: Entreprise;
  actif?: boolean;
  creationDate?: string;
}

export class Entreprise {
  id?: number;
  name?: string;
  numero?: string;
  creationDate?: string;
  responsable?: User;
  collaborateurs: User[] = [];
}
